import { Popup } from 'components/popup';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import { useEwa } from 'providers/ewa';
import moment from 'moment';
import styles from './styles.module.scss';

interface EwaEditAccessModalProps {
  isShow: boolean;
  onClose: () => void;
}

export const EwaEditAccessModal = ({
  isShow,
  onClose,
}: EwaEditAccessModalProps) => {
  const { data } = useEwa();
  return (
    <>
      {isShow && (
        <Popup
          onClose={onClose}
          stylesWrapper={styles.popup}
        >
          <div className={styles.text}>
            <Typography className={styles.textTitle}>
              Edit Earned Wage Access
            </Typography>
            <Typography>
              You have already used the Earned Wage Access option during this
              pay cycle. This feature will be available again at the end of the
              next pay period on{' '}
              {moment(data?.nextPayDate).format('MM/DD/YYYY')}
            </Typography>
          </div>
          <div className={styles.btn}>
            <Button
              variant='text'
              onClick={onClose}
            >
              got it
            </Button>
          </div>
        </Popup>
      )}
    </>
  );
};
