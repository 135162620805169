import { BANKS_TAB } from 'pages/home/enums';
import { buildQs } from './shared/utils/build-qs';

interface HomeQs {
  bankTab?: BANKS_TAB;
}

interface AccountByIdQs {
  accountTab?: 'cards' | 'transactions';
}

export enum CardSubRoute {
  manageCard = 'manage-card',
  cardActions = 'card-actions',
  limits = 'limits',
  autorizedCards = 'autorized-cards',
}

export enum TransferRoute {
  batchTransfer = 'batch-transfer',
}
export enum TransferQs {
  fromConnectedAccount = 'from-connected-account',
  betweenOwnAccounts = 'between-own-accounts',
}

export enum VerifyDocsSubRoute {
  inProcess = 'in-process',
}

export enum VerifyInfoSubRoute {
  failure = 'failure',
}

export const CARD_ORDER_STATUS_QS_NAME = 'orderStatusId';

export enum RewardsRouteSubRoute {
  dashboard = 'dashboard',
  history = 'history',
  myGiftCard = 'myGiftCard',
}

export enum SettingsRouteSubRoute {
  support = 'support',
  security = 'security',
}

export const homeSearchParams = { drawerMode: 'drawerMode' };

export const appRoutes = {
  signUp: () => `/signup`,
  signUpPersonal: () => `/signup/personal`,
  signUpBusiness: () => `/signup/business`,
  login: () => `/login`,
  home: (qs?: HomeQs) => buildQs(`/home`, qs),
  homeAllCards: () => `home/all-cards`,
  accountById: (accountId: string, qs?: AccountByIdQs) =>
    buildQs(`/account/${accountId}`, qs),
  othereBankByName: (bankName: string) => `/othere-bank/${bankName}`,
  externalAccountById: (accountId: string) => {
    return `/external-account/${accountId}`;
  },
  cardById: (
    accountId: string,
    cardId: string,
    subRoute?: CardSubRoute,
    rest?: string
  ) =>
    `/account/${accountId}/card/${cardId}${subRoute ? `/${subRoute}` : ''}${
      rest ? rest : ''
    }`,

  // it is resolver for support old components
  // instead you need to use appRoutes.cardById
  cardTransactions: (cardId: string) => `/card-transactions/${cardId}`,

  transfer: (subRoute?: TransferRoute, qs?: any) =>
    buildQs(`/transfes${subRoute ? `/${subRoute}` : ''}`, qs),

  verifyInfo: (subRoute?: VerifyInfoSubRoute) =>
    `/verify-your-info${subRoute ? `/${subRoute}` : ''}`,
  verifyDocs: (subRoute?: VerifyDocsSubRoute) =>
    `/verify-doc${subRoute ? `/${subRoute}` : ''}`,

  rewards: (subRoute?: RewardsRouteSubRoute, qs?: any) =>
    buildQs(`/rewards${subRoute ? `/${subRoute}` : ''}`, qs),
  settings: (subRoute?: SettingsRouteSubRoute, qs?: any) =>
    buildQs(`/settings${subRoute ? `/${subRoute}` : ''}`, qs),
} as const;
