import { DrawerStepper, TStepOfDrawerStepper, useDrawerStepperContext } from 'shared/ui/drawer-stepper'
import List from '../List';

interface DrawerManageExternalBankProps {
  isShow: boolean;
  onClose: () => void;
}

const steps: Array<[string, TStepOfDrawerStepper]> = [
  [
    '0',
    {
      id: '0',
      isCanGoBack: false,
      title: 'Connect external bank account',
      prevId: null,
      Element: <List />,
    },
  ],
]

const MapSteps = new Map(steps)

const DrawerManageExternalAccount = ({ isShow, onClose }: DrawerManageExternalBankProps) => {
  return (
    <DrawerStepper 
      isShow={isShow} 
      onClose={onClose} 
      steps={MapSteps} 
      startStep={"0"} 
    />
  );
}

export default DrawerManageExternalAccount;