export enum EditType {
  Email = 'Email',
  Phone = 'Phone',
  Address = 'Address',
  EmployerInformation = 'EmployerInformation',
}

export enum SectionPerson {
  AuthorizedPerson = 'Authorized Person',
  Business = 'Business',
  FirstOwner = '1',
  SecondOwner = '2',
  ThirdOwner = '3',
  FourthOwner = '4',
}

export enum EwaFrequencyTiltle {
  ForThisTimeOnly = 'For this time only',
  ForEveryFuturePaycheck = 'For every future paycheck',
}
