import { TransitionAnimation } from 'shared/ui/animations';
import { usePayeeHistoryAllControllerQuery } from 'api/endpoints/financialAccounts';
import { Skeleton } from 'components/skeleton';
import { ConnectedPayeeHistory, EmptyPayees } from 'entities/transfers';
import { AttacheCaseIcon, InfoIcon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Typography } from 'shared/ui/typography';

const WARNING_TEXT =
  'It usually takes 5-10 minutes to verify your newly added external account. You`ll see the status update reflected soon.';

export const ConnectedAccountHistory = () => {
  const {
    data: payeeHistory,
    isLoading: isLoadingPayeeHistory,
    isFetching: isFetchingPayeeHistory,
  } = usePayeeHistoryAllControllerQuery();

  const renderPayeeList = () => {
    if (!payeeHistory?.length) {
      return (
        <EmptyPayees
          title={'No connected financial accounts'}
          subTitle={'Connected financial accounts will be displayed here'}
          icon={<AttacheCaseIcon />}
        />
      )
    }

    return payeeHistory.map((payee) => (
      <ConnectedPayeeHistory
        key={payee.id}
        payee={payee}
      />
    ))
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.boxWarning}>
          <InfoIcon />
          <Typography>{WARNING_TEXT}</Typography>
        </div>
        {isLoadingPayeeHistory || isFetchingPayeeHistory ? (
          <Skeleton
            width='100%'
            height='170px'
          />
        ) : (
          renderPayeeList()
        )}
      </div>
    </TransitionAnimation>
  );
};
