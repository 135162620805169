export enum CurrencyCode {
  USD = 840,
  EUR = 978,
}

export enum CountryCodeAlpha3 {
  USA = 'USA',
}

export enum CurrencyCodeSign {
  USD = '$',
  EUR = '€',
}

export enum ShortMessageType {
  ApproveTransaction = 'APPROVE_TRANSACTION',
  ApprovePayee = 'APPROVE_PAYEE',
  ApproveLinkedAccount = 'ApproveLinkedAccount',
  RestorePassword = 'RestorePassword',
  AttemptLogin = 'AttemptLogin',
  TransferPoints = 'TransferPoints',
  ContactInfoUpdateRequest = 'ContactInfoUpdateRequest',
  SetEwaRequest = 'SetEwaRequest'
}

export enum NotificationsGroup {
  Notifications ='Notifications',
  Messages = 'Messages',
  Campaigns = 'Campaigns'
}

export enum NotificationsLevel {
  Info = 'Info',
  Warning = 'Warning'
}
export enum SecureOperationType {
  TimeBasedCode = 'TimeBasedCode',
  ShortMessageCode = 'ShortMessageCode',
}

export enum FormFactor {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

export enum Status {
  ActivationRequired = 'ACTIVATION_REQUIRED',
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

