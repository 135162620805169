import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { useDebounce } from 'shared/hooks/useDebounce';
import {
  useNeteviaPayeesControllerQuery,
  useSearchClientsMutation,
  SearchClientsApi,
} from 'api/endpoints/fundsMovement';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { TransitionAnimation } from 'shared/ui/animations';
import styles from './styles.module.scss';
import { useAppSelector, useAppDispatch } from 'shared/models';
import {
  findObjById,
  FREQUENCY_DATA,
  FrequencyTitle,
} from 'entities/transfers';
import helper from 'services/helper';
import { MakeTransferForm } from './make-transfer-form';
import { Typography } from 'shared/ui/typography';
import { MagnifyingGlassIcon } from 'shared/ui/icons';
import {
  setFromFinancialAccount,
  setToCustomer,
  setAmount,
  setSelectFrequency,
  setSelectDateTransfer,
  selectFromFinancialAccount,
  selectToCustomer,
  selectAmount,
  selectFrequency,
  selectDateTransfer,
  setNotRegisteredPayee,
  selectTransferMemo,
  setTransferMemo,
  selectNotRegisteredPayee,
  setFilteredPayees,
  selectFilteredPayees,
} from 'features/transfers/transfer-to-netevia-customer/model';
import { useBoolean } from 'shared/hooks/useBoolean';
import moment from 'moment';
import { useSnackBar } from 'providers/snackBar.provider';
import { Skeleton } from 'components/skeleton';
import { formatAccountName } from 'shared/lib/format';
import { useFormik } from 'formik';
import { createAchOrNeteviaTransferSchema } from 'entities/transfers/model/achOrNeteviaTransferSchema';
import dayjs from 'dayjs';

export const MakeTransfer = () => {
  const dispatch = useAppDispatch();
  const { goNext } = useDrawerStepperContext();
  const { setSnackBar } = useSnackBar();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { data: neteviaPayees, isLoading: isLoadingNeteviaPayees } =
    useNeteviaPayeesControllerQuery();

  const [
    searchClients,
    { data: searchedClients, isSuccess },
  ] = useSearchClientsMutation();

  const fromFinancialAccount = useAppSelector(selectFromFinancialAccount);
  const toCustomer = useAppSelector(selectToCustomer);
  const amount = useAppSelector(selectAmount);
  const frequency = useAppSelector(selectFrequency);
  const dateTransfer = useAppSelector(selectDateTransfer);
  const memo = useAppSelector(selectTransferMemo);
  const filteredPayees = useAppSelector(selectFilteredPayees);

  const notRegisteredPayee = useAppSelector(selectNotRegisteredPayee);

  const notRegisteredPayeeBool = useBoolean();

  useEffect(() => {
    if (searchedClients && isSuccess) {
      dispatch(
        setFilteredPayees({
          value: searchedClients.map((item) => ({
            ...item,
            name: item.doingBusinessName,
            id: item.financialAccountId,
            last4: item.accountNumberLast4,
          })),
        })
      );
    }
  }, [searchedClients]);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      from: fromFinancialAccount?.id || '',
      to: toCustomer?.id || '',
      amount: amount || '',
      frequency: frequency,
      dateTransfer: dateTransfer ? dayjs(dateTransfer) : dayjs().endOf('day'),
      memo: memo,
    },
    validationSchema: createAchOrNeteviaTransferSchema(
      unclosedFinancialAccounts
    ),
    onSubmit: (form) => {
      dispatch(
        setFromFinancialAccount({
          value: findObjById(form.from, unclosedFinancialAccounts),
        })
      );

      const toCustomerFromNetevia = findObjById(form.to, neteviaPayees?.payees);
      const toCustomerFromFiltered = findObjById(form.to, filteredPayees);

      if (toCustomerFromNetevia.id) {
        dispatch(
          setToCustomer({
            value: toCustomerFromNetevia,
          })
        );
      } else if (toCustomerFromFiltered.id) {
        dispatch(
          setNotRegisteredPayee({
            value: filteredPayees?.find(
              (payee) => payee.id === toCustomerFromFiltered.id
            ),
          })
        );
        dispatch(
          setToCustomer({
            value: toCustomerFromFiltered,
          })
        );
      }

      dispatch(setAmount({ value: form.amount }));

      dispatch(setSelectFrequency({ value: form.frequency }));

      dispatch(
        setSelectDateTransfer({
          value:
            dayjs.isDayjs(form?.dateTransfer) &&
            form.dateTransfer.isValid() &&
            !form.dateTransfer.isBefore(dayjs(), 'day')
              ? form.dateTransfer.endOf('day').toISOString()
              : dayjs().endOf('day').toISOString(),
        })
      );
      dispatch(setTransferMemo({ value: form.memo }));
      goNext('1');
    },
  });

  const handleDisabledDateAndFrequency = () => {
    const inOptions = findObjById(formik.values.to, filteredPayees);

    if (
      inOptions.id ||
      (formik.values.to && formik.values.to === notRegisteredPayee?.id)
    ) {
      formik.setFieldValue('frequency', FrequencyTitle.Once);
      formik.setFieldValue('dateTransfer', dayjs().startOf('day'));
      notRegisteredPayeeBool.setTrue();
      setSnackBar({
        type: 'info',
        message:
          'Schedule and recurring transfers are only available for registered payees. Please add a payee before scheduling transfers.',
        isShow: true,
        width: '400px',
      });
    } else {
      notRegisteredPayeeBool.setFalse();
    }
  };

  useEffect(() => {
    if (formik.values.to) {
      handleDisabledDateAndFrequency();
    }
  }, [formik.values.to]);

  const handleSearchPayee = useDebounce(
    async ({
      target: { value },
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!value) {
        dispatch(setFilteredPayees({ value: null }));
        return;
      }
      searchClients({ whatsLookingFor: value });
    },
    500
  );

  const showNotRegisteredPayee = () => {
    if (filteredPayees) {
      return !!filteredPayees?.length ? (
        filteredPayees?.map((payee) => (
          <MenuItem
            key={payee.id}
            value={payee.id}
          >
            {`${payee.name} (${payee.last4})`}
          </MenuItem>
        ))
      ) : (
        <div className={styles.empty}>
          <MagnifyingGlassIcon />
          <Typography>
            The payee with the entered data was not found in our system.
          </Typography>
        </div>
      );
    }
  };
  const showRegisteredPayee = neteviaPayees?.payees?.map((payee) => (
    <MenuItem
      key={payee.id}
      value={payee.id}
    >
      {`${payee.name} (${payee.last4})`}
    </MenuItem>
  ));

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const optionsFrequency = FREQUENCY_DATA.map((el) => ({
    id: el.title,
    value: el.title,
    content: el.title,
  }));

  return financialAccountsIsLoading || isLoadingNeteviaPayees ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <MakeTransferForm
        formik={formik}
        optionsFromAcc={optionsFromAcc}
        handleSearchPayee={handleSearchPayee}
        optionsFrequency={optionsFrequency}
        disabledDateAndFrequenc={notRegisteredPayeeBool.value}
        showRegisteredPayee={showRegisteredPayee}
        showNotRegisteredPayee={showNotRegisteredPayee}
      />
    </TransitionAnimation>
  );
};
