import { ChangeEvent, useEffect, useState } from 'react';
import {
  DocumentUploadRequirementApi,
  RequiredDocument,
  useDocumentUploadRequirementQuery,
  useEndSessionDocHNMutation,
  useEndSessionDocMutation,
  useUploadDocumentForVerificationHNMutation,
  useUploadDocumentForVerificationMutation,
} from 'api/endpoints/account';
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentList from './components/documentList';
import { Button, css, styled, Typography } from '@mui/material';
import { convertFileToBase64 } from 'shared/utils/convertFileToBase64';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import LoadingButton from '@mui/lab/LoadingButton';
import { Skeleton } from 'components/skeleton';
import { useBoolean } from '../../shared/hooks/useBoolean';
import { allDocsIsSubmitted } from '../../shared/utils/login';

export interface Document {
  createdAt: Date;
  updatedAt: Date;
  status: string;
  documentUploadSession: {
    type: number;
    id: string;
    primaryDocumentTypes: string;
  };
}

export interface ObjFiles {
  file: File;
  documentUploadSessionId: string;
  documentType: string;
  verificatedEntity: string;
}

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 10 MB

interface Props {
  userId?: any;

  onAllDocsSubmit(): void;

  submitButtonText: string;
  isBackButtonHidden?: boolean;
  successText: string;
}

const GroupButton = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    margin-top: 8px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: row-reverse;
      align-items: center;
      gap: 24px;
      margin-top: 24px;
    }
  `}
`;

const GroupLists = styled('div')`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 16px;

    ${theme.breakpoints.down('sm')} {
      gap: 32px;
    }
  `}
`;

const documentSections: {
  [section in keyof DocumentUploadRequirementApi]: {
    title: string;
    verificatedEntity: string;
  };
} = {
  businessProfile: {
    title: 'Business information',
    verificatedEntity: 'Business',
  },
  primaryAuthorizedPerson: {
    title: 'Primary authorized user',
    verificatedEntity: 'Personal',
  },
  ultimateBeneficialOwners: {
    title: 'Ultimate Beneficial Owners',
    verificatedEntity: 'Owner',
  },
  military: {
    title: 'Military information',
    verificatedEntity: 'Military',
  },
  taxes: {
    title: 'Taxes information',
    verificatedEntity: 'Taxes',
  },
  bills: {
    title: 'Bills information',
    verificatedEntity: 'Bills',
  },
  statements: {
    title: 'Statements information',
    verificatedEntity: 'Statements',
  },
  insurance: {
    title: 'Insurance information',
    verificatedEntity: 'Insurance',
  },
  iDs: {
    title: 'ID information',
    verificatedEntity: 'IDs',
  },
  other: {
    title: 'Other information',
    verificatedEntity: 'Other',
  },
  currentVerification: {
    title: 'Required docs',
    verificatedEntity: 'CurrentVerification',
  },
};

export const VerificationDocument = ({
  userId,
  onAllDocsSubmit,
  submitButtonText,
  isBackButtonHidden,
  successText,
}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSnackBar } = useSnackBar();
  const token = location.state?.token;
  const statusHN = location.state?.statusHN;

  const {
    data: docs,
    isFetching: documentRequirementIsLaoding,
    refetch: documentRequirementRefetch,
  } = useDocumentUploadRequirementQuery({ token });

  const uploadProcessingBool = useBoolean();

  const [uploadDocsMutation] = useUploadDocumentForVerificationMutation();
  const [uploadDocsMutationHN] = useUploadDocumentForVerificationHNMutation();

  const [endSessionDocHN] = useEndSessionDocHNMutation();
  const [endSessionDoc] = useEndSessionDocMutation();

  const [files, setFiles] = useState<ObjFiles[]>([]);

  useEffect(() => {
    if (docs && allDocsIsSubmitted(docs)) {
      onAllDocsSubmit();
    }
  }, [docs, onAllDocsSubmit]);

  const transformPrimaryDocumentTypes = (arr: RequiredDocument[]) =>
    arr.flatMap((documentItem) => {
      return documentItem.documentUploadSession.primaryDocumentTypes.map(
        (item) => {
          return {
            ...documentItem,
            documentUploadSession: {
              ...documentItem.documentUploadSession,
              primaryDocumentTypes: item,
            },
          };
        }
      );
    });

  const sumbitDocs = async () => {
    try {
      uploadProcessingBool.setTrue();

      for (const item of files) {
        if (item.file) {
          const fileBase64 = (await convertFileToBase64(item.file)) as string;
          const formattedFileBase64 = fileBase64?.substring(
            fileBase64.indexOf(',') + 1
          );

          const queryArg = {
            profileId: userId,
            documentUploadSessionId: item.documentUploadSessionId,
            documentType: item.documentType,
            fileName: item.file.name,
            data: formattedFileBase64,
            contentType: item.file.type,
            verificatedEntity: item.verificatedEntity,
          };

          if (statusHN) {
            await uploadDocsMutationHN({
              token,
              queryArg,
            }).unwrap();

            try {
              await endSessionDocHN({
                token,
                profileId: userId,
                verificatedEntity: item.verificatedEntity,
                documentUploadSessionId: item.documentUploadSessionId,
              }).unwrap();
            } catch(err) {
              // silence
            }
          } else {
            await uploadDocsMutation({
              token,
              queryArg,
            }).unwrap();
          }
        }
      }

      await helper.sleep(5_000);
      const docs = await documentRequirementRefetch().unwrap();
      const isAllDocsSubmitted = allDocsIsSubmitted(docs);

      if (!statusHN && isAllDocsSubmitted && files.length > 0) {
        await endSessionDoc({
          token,
          documentUploadSessionId: files[0].documentUploadSessionId,
        }).unwrap();
      }

      setFiles([]);

      if (isAllDocsSubmitted) {
        setSnackBar({
          type: 'success',
          message: successText || 'success',
          isShow: true,
        });
      }
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    } finally {
      uploadProcessingBool.setFalse();
    }
  };

  const handleFileUpload = (
    e: ChangeEvent<HTMLInputElement>,
    type: string,
    id: string,
    verificatedEntity: string
  ) => {
    if (e.target.files) {
      const file = e.target.files[0];

      if (MAX_FILE_SIZE > file.size) {
        const fileObj = {
          file,
          documentUploadSessionId: id,
          documentType: type,
          verificatedEntity,
        };

        setFiles((prevFiles: ObjFiles[]) => [...prevFiles, fileObj]);
      } else {
        setSnackBar({
          type: 'error',
          message: 'The maximum allowed file size for uploads is 20 MB',
          isShow: true,
        });
      }
    }
  };

  return documentRequirementIsLaoding ? (
    <Skeleton
      width='100%'
      height={200}
    />
  ) : (
    <>
      <GroupLists>
        {Object.keys(documentSections).map((key) => {
          const documentSectionKey = key as keyof DocumentUploadRequirementApi;
          const reqDocs =
            (docs && docs[documentSectionKey]?.requiredDocuments) || [];
          const documents = transformPrimaryDocumentTypes(reqDocs);

          const isNeedRender = documents.some(
            (doc) => doc?.documentUploadSession?.primaryDocumentTypes?.length
          );

          if (!isNeedRender) {
            return null;
          }

          const sectionTitle = documentSections[documentSectionKey]
            ? documentSections[documentSectionKey].title
            : 'Other Documents';

          const sectionEntity = documentSections[documentSectionKey]
            ? documentSections[documentSectionKey].verificatedEntity
            : 'Other';

          return (
            <DocumentList
              key={documentSectionKey}
              title={sectionTitle}
              array={documents}
              uploadFile={handleFileUpload}
              setFile={setFiles}
              files={files}
              verificatedEntity={sectionEntity}
              isDeleteDisabled={uploadProcessingBool.value}
            />
          );
        })}
      </GroupLists>
      <br />
      <GroupButton>
        <LoadingButton
          variant='contained'
          onClick={sumbitDocs}
          disabled={!files.length}
          loading={uploadProcessingBool.value}
          sx={{
            background: '#023047',
          }}
        >
          <div>{submitButtonText}</div>
        </LoadingButton>
        {!isBackButtonHidden && (
          <Button
            variant='text'
            disabled={uploadProcessingBool.value}
            onClick={() => {
              navigate('/');
            }}
          >
            Back
          </Button>
        )}
      </GroupButton>
    </>
  );
};
