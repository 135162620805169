import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { appRoutes } from "routes"
import { BANK_TAB } from "./constants";
import { BANKS_TAB } from "./enums";

export const useIsExternalBankTab = () => {
  const [searchParams] = useSearchParams();

  return useMemo(() => searchParams.get(BANK_TAB) === BANKS_TAB.EXTERNAL_BANKS, [searchParams]) 
}

export const useNavigateToExternalAccounts = () => {
  const navigate = useNavigate();

  const navigateToExternalAccounts = useCallback(() => {
    navigate(appRoutes.home({ bankTab: BANKS_TAB.EXTERNAL_BANKS }));
  }, []);

  return { navigateToExternalAccounts };
};

export const useNavigateToExternal = () => {
  const navigate = useNavigate();

  const navigateToExternalAccount = useCallback((accountId: string) => {
    navigate(appRoutes.externalAccountById(accountId));
  }, []);

  return { navigateToExternalAccount };
}