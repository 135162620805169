import { IconButton } from '@mui/material';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useChangeLoginMutation } from 'api/endpoints/account';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useConfirmDialog } from 'providers/confirm.provider';
import styles from './style.module.scss';
import helper from 'services/helper';
import { useSnackBar } from 'providers/snackBar.provider';
import { useSearchParams } from 'react-router-dom';
import { LOGIN_OPTIONS } from 'pages/settings/security/securitySearchParams';
import { Button } from 'shared/ui/buttons';
import { TextInput } from 'shared/ui/inputs';
import { useFormik } from 'formik';
import { useBoolean } from 'shared/hooks/useBoolean';
import { ChangeEvent } from 'react';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  newLogin: yup
    .string()
    .required('New login is required')
    .min(6, 'New login must be at least 6 characters long')
    .max(40, 'New login must not exceed 40 characters'),

  password: yup.string().required('Password is required'),
});

const ChangeLogin = () => {
  const { setSnackBar } = useSnackBar();
  const { t } = useTranslationProvider();
  const [, setSearchParams] = useSearchParams();
  const confirm = useConfirmDialog();
  const [changeLoginMutation, { isLoading: changeLoginIsLoading }] =
    useChangeLoginMutation();

  const showPassword = useBoolean();

  const changeLogin = (newLogin: string, password: string) => {
    confirm.show({
      applyButtonText: t('common.Confirm', {
        defaultValue: 'Confirm',
      }),
      cancelButtonText: t('common.Cancel', {
        defaultValue: 'Cancel',
      }),
      dialogTitle: t('Settings.Change Login', {
        defaultValue: 'Change Login',
      }),
      dialogText: `${t('Settings.Do you want to change', {
        defaultValue: 'Do you want to change your current login to',
      })} "${newLogin}"?`,
      cancelButtonType: 'text',
      cancelError: true,
      typeNew: true,
      async onApply() {
        try {
          await changeLoginMutation({
            login: newLogin,
            password: password,
          }).unwrap();
          setSnackBar({
            type: 'success',
            message: t('Settings.Change Login - Success', {
              defaultValue: 'Change Login - Success',
            }),
            isShow: true,
          });

          setSearchParams({ page: LOGIN_OPTIONS });
        } catch (e: any) {
          console.log('error', e);
          setSnackBar({
            type: 'error',
            message: helper.formatErrors(e.data),
            isShow: true,
          });
        }
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      newLogin: '',
      password: '',
    },
    validationSchema,
    onSubmit: (form) => {
      changeLogin(form.newLogin, form.password);
    },
  });

  return (
    <form
      className={styles.form}
      onSubmit={formik.handleSubmit}
    >
      <div className={styles.container}>
        <TextInput
          label={t('Settings.New login', {
            defaultValue: 'New login',
          })}
          autoComplete='new-username'
          inputProps={{ maxLength: 40 }}
          {...formik.getFieldProps('newLogin')}
          name='newLogin'
          value={formik.values.newLogin}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldTouched('newLogin', true);
            formik.setFieldValue('newLogin', event.target.value.trim());
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.newLogin && formik.errors.newLogin}
        />
        <TextInput
          label={t('Login.Password', {
            defaultValue: 'Password',
          })}
          autoComplete='new-password'
          type={showPassword.value ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => showPassword.toggle()}
                edge='end'
              >
                {showPassword.value ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
          {...formik.getFieldProps('password')}
          error={formik.touched.password && formik.errors.password}
        />
        <Button
          type='submit'
          loading={changeLoginIsLoading}
          variant='contained'
          className={styles.btn}
          disabled={!formik.isValid || !formik.dirty}
        >
          {t('common.Save Changes', {
            defaultValue: 'Save Changes',
          })}
        </Button>
      </div>
    </form>
  );
};
export default ChangeLogin;
