import { LoanStatusEnum } from 'pages/home/components/loans/types';
import { PieChart } from '@mui/x-charts/PieChart';
import { replaceNumberWithComma } from 'shared/utils/price';
import styles from './style.module.scss';
import { Loan } from 'api/endpoints/loans';
interface ChartProps {
  loans: Loan;
}
export const Chart = ({ loans }: ChartProps) => {
  const isDisable = LoanStatusEnum.WrittenOff === loans?.status;

  const originalFundingColor = isDisable
    ? 'rgba(0, 0, 0, 0.3)'
    : 'rgba(24, 122, 201, 1)';

  const amountRemainingColor = isDisable
    ? 'rgba(0, 0, 0, 0.12)'
    : 'rgba(24, 122, 201, 0.3)';

  return (
    <div className={styles.wrapper}>
      <PieChart
        series={[
          {
            data: [
              {
                value: loans?.currentAmount,
                color: amountRemainingColor,
                label: 'Amount Remaining',
              },
              {
                value: loans?.originalAmount - loans?.currentAmount,
                color: originalFundingColor,
                label: 'Original Funding',
              },
            ],
            valueFormatter: (v) => `$${replaceNumberWithComma(v.value ?? 0)}`,
            innerRadius: 40,
          },
        ]}
        width={150}
        height={150}
        margin={{ right: 0, top: 0 }}
        slotProps={{
          legend: { hidden: true },
          popper: { hidden: true },
        }}
      />
    </div>
  );
};
