import { FC } from "react";
import Cardlogo from "assets/svg/cardlogo.svg";
import { useDefaultCardName } from 'shared/hooks/useDefaultCardName';
import styles from "./style.module.scss";

interface CardHeaderProps {
  isBurner: boolean;
  formFactor: string;
  cardHolder: string;
}

const BURNER = "burner";

const CardHeader: FC<CardHeaderProps> = ({
  isBurner,
  formFactor,
  cardHolder,
}) => {
  const defaultCardName = useDefaultCardName();

  return (
    <div className={styles.box}>
      <span>{cardHolder ?? defaultCardName}</span>
      <div className={styles.box_logo}>
        <img src={Cardlogo} />
        <p>{isBurner ? BURNER : formFactor?.toLocaleLowerCase()} card</p>
      </div>
    </div>
  );
};

export default CardHeader;
