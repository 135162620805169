import { EwaFrequencyTiltle } from '../types';

export const getEwaFrequencyNumber = (text: string): number => {
  switch (text) {
    case EwaFrequencyTiltle.ForThisTimeOnly:
      return 1;
    case EwaFrequencyTiltle.ForEveryFuturePaycheck:
      return 2;
    default:
      return 0;
  }
};
