import { useEffect } from 'react';
import { AnimateWrapper } from 'components/animate-wrapper';
import { EwaEmpty } from './componentns/ewaEmpty';
import { Skeleton } from 'components/skeleton';
import { EwaTable } from './componentns/ewaTable';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { useEwa } from '../../../providers/ewa';
import { useSaveEarnedWageAccessMutation } from 'api/endpoints/pinWheel';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';

export const EarnedWageAccess = () => {
  const { setSnackBar } = useSnackBar();
  const { data, isLoading, connectedAccountName, show, isBorrower } = useEwa();
  const [saveEwaMutation, { isLoading: isLoadingSaveEwa }] =
    useSaveEarnedWageAccessMutation();

  useEffect(() => {
    if (isBorrower) {
      show();
    }
  }, []);

  const saveEwa = async () => {
    try {
      await saveEwaMutation({
        active: false,
      }).unwrap();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const renderContent = () => {
    if (data?.currentStatus.isActive) {
      return (
        <>
          <EwaTable
            data={data}
            connectedAccountName={connectedAccountName}
          />
          <div className={styles.boxBtn}>
            <Button
              variant='text'
              color='error'
              onClick={saveEwa}
              disabled={isBorrower}
              loading={isLoadingSaveEwa}
            >
              cancel Earned wage access
            </Button>
            <Button
              onClick={() => show()}
              disabled={isBorrower}
            >
              Edit
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <EwaEmpty />
        <Button
          className={styles.connectBtn}
          onClick={() => show()}
        >
          Connect Earned wage access
        </Button>
      </>
    );
  };

  return (
    <div className={styles.contaniner}>
      {isLoading ? (
        <Skeleton
          width='100%'
          height='110px'
        />
      ) : (
        <AnimateWrapper className='fade'>{renderContent()}</AnimateWrapper>
      )}
    </div>
  );
};
