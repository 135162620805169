import { useTranslationProvider } from 'providers/translation/translation.provider';
import { useSearchParams } from 'react-router-dom';
import styles from './style.module.scss';
import { InfoItem } from './InfoItem';
import DrawerModal from 'shared/components/drawerModal';
import ArrowLeft from 'assets/svg/arrow-left-gray.svg';
import { useLimitsAndRewardsConditions } from 'api/hooksApi/useLimitsAndRewardsConditions';
import { usePermission } from 'shared/hooks/usePermission';
import { ListButton } from 'shared/ui/lists';
import { Button } from 'shared/ui/buttons';
import {
  ChevronRightIcon,
  DollarCircleIcon,
  GiftCardIcon,
} from 'shared/ui/icons';
import { useScreenSize } from 'shared/hooks/useScreensSize';
import { Skeleton } from 'components/skeleton';
import { AnimateWrapper } from 'components/animate-wrapper';

enum LimitPageMode {
  Transaction = 'TransactionLimits',
  Reward = 'RewardsLimits',
}

enum LimitTitle {
  Transaction = 'Transaction limits',
  Reward = 'Rewards limits & conditions',
}

export const LimitsConditions = () => {
  const { isMobile } = useScreenSize();
  const { t } = useTranslationProvider();
  const { limitsAndConditionsPermission, rewardConditionsPermission } =
    usePermission();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || '';
  const mode = searchParams.get('mode') || '';

  const { conditions, conditionsIsLoading } = useLimitsAndRewardsConditions();

  const isShowDrawer =
    mode === LimitPageMode.Transaction || mode === LimitPageMode.Reward;

  const handleClickButton = (mode: string) => {
    setSearchParams({ page, mode });
  };

  const renderDrawerConten = () => {
    let itemsToRender;

    if (mode === LimitPageMode.Transaction) {
      itemsToRender = conditions?.transactionLimitsItems;
    } else if (mode === LimitPageMode.Reward) {
      itemsToRender = conditions?.rewardsLimitsItems;
    }
    return itemsToRender?.map((item) => (
      <InfoItem
        key={item.name}
        {...item}
      />
    ));
  };

  return (
    <div className={styles.container}>
      {isMobile && (
        <div className={styles.container_nav}>
          <img
            src={ArrowLeft}
            onClick={() => setSearchParams({})}
          />
          <span className={styles.container_nav_title}>
            {t('Settings.Company information', {
              defaultValue: 'Company information',
            })}
          </span>
        </div>
      )}
      {conditionsIsLoading ? (
        <Skeleton
          width='100%'
          height='110px'
        />
      ) : (
        <AnimateWrapper className='fade'>
          <ListButton className={styles.listBtn}>
            {limitsAndConditionsPermission && (
              <Button
                key={LimitTitle.Transaction}
                startIcon={<DollarCircleIcon />}
                endIcon={<ChevronRightIcon />}
                onClick={() => handleClickButton(LimitPageMode.Transaction)}
              >
                {LimitTitle.Transaction}
              </Button>
            )}
            {rewardConditionsPermission && (
              <Button
                key={LimitTitle.Reward}
                startIcon={<GiftCardIcon />}
                endIcon={<ChevronRightIcon />}
                onClick={() => handleClickButton(LimitPageMode.Reward)}
              >
                {LimitTitle.Reward}
              </Button>
            )}
          </ListButton>
        </AnimateWrapper>
      )}

      <DrawerModal
        isShow={isShowDrawer}
        onClose={() => setSearchParams({ page })}
        titleText={
          mode === LimitPageMode.Transaction
            ? LimitTitle.Transaction
            : LimitTitle.Reward
        }
      >
        {renderDrawerConten()}
      </DrawerModal>
    </div>
  );
};
