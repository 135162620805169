import React, { FC } from "react";
import { Button, css, styled, Typography } from "@mui/material";
import uploadIcon from "assets/svg/upload-gray.svg";
import IconButton from "@mui/material/IconButton";
import successIcon from "assets/svg/success-circle-green.svg";
import deleteIcon from "assets/svg/close-red.svg";

interface DocumentItemProps {
  name: string;
  uploadFile: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
    id: string,
    verificatedEntity: string
  ) => void;
  fileName: string | undefined;
  status: string;
  idSession: string;
  verificatedEntity: string;
  deleteFile: () => void;
  isDeleteDisabled?: boolean;
}

const DocumentItem: FC<DocumentItemProps> = ({
  name,
  uploadFile,
  fileName,
  status,
  idSession,
  verificatedEntity,
  deleteFile,
  isDeleteDisabled,
}) => {
  const renderItem = () => {
    if (status === "SUBMITTED") {
      return (
        <Box>
          <img src={successIcon} alt="Success" />
          <Label>{name}</Label>
        </Box>
      );
    } else if (fileName) {
      return (
        <>
          <Box>
            <img src={successIcon} alt="Success" />
            <Label>{fileName}</Label>
          </Box>
          <IconButton disabled={isDeleteDisabled} aria-label="Delete" onClick={deleteFile}>
            <img src={deleteIcon} alt="Delete" />
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <Label>{name}</Label>
          <Button
            component="label"
            sx={{
              minWidth: "30px",
              borderRadius: "50%",
            }}
          >
            <img src={uploadIcon} alt="Upload" />
            <VisuallyHiddenInput
              type="file"
              accept=".pdf,.jpeg,.jpg,.png"
              onChange={(e) =>
                uploadFile(e, name, idSession, verificatedEntity)
              }
            />
          </Button>
        </>
      );
    }
  };

  return <Wrapper>{renderItem()}</Wrapper>;
};

export default DocumentItem;

const Wrapper = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    & > img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  `}
`;
const Label = styled(Typography)`
  ${({ theme }) => css`
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    letter-spacing: 0.17px;
    max-width: 295px;
    overflow-wrap: break-word;
    word-break: break-all;

  `}
`;
const Box = styled("div")`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    & > img {
      width: 24px;
      height: 24px;
      margin: 8px 0px;
    }
  `}
`;
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
