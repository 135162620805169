import { Alert } from '@mui/material';
import { loanInfoWarningText } from 'pages/home/components/loans/constants';
import { LoanStatusEnum } from 'pages/home/components/loans/types';
import { Button } from '@mui/material';
import styles from './style.module.scss';

interface AlertLoansInfoProps {
  status: string;
  signUrl: string;
  statusRepaid: boolean;
}
export const AlertLoansInfo = ({
  status,
  signUrl,
  statusRepaid,
}: AlertLoansInfoProps) => {
  const warningText = loanInfoWarningText[status];

  const getAlertColor = (status: string) => {
    switch (status) {
      case LoanStatusEnum.Repaid:
        return 'success';
      case LoanStatusEnum.DiscountedSettlement:
        return 'info';
      default:
        return 'error';
    }
  };

  return warningText ? (
    <Alert
      severity={getAlertColor(status)}
      classes={{
        action: styles.alertAction,
        icon: styles.alertIcon,
        root: styles.alertRoot,
      }}
      action={
        status !== LoanStatusEnum.Defaulted &&
        status !== LoanStatusEnum.DiscountedSettlement && (
          <Button
            color={statusRepaid ? 'success' : 'error'}
            variant='text'
            className={styles.btn}
            onClick={() => window.open(signUrl, '_blank')}
          >
            {statusRepaid ? 'View details' : 'Сontact us'}
          </Button>
        )
      }
    >
      {warningText}
    </Alert>
  ) : (
    <></>
  );
};
