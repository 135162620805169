import { Button } from 'shared/ui/buttons';
import { TransitionAnimation } from 'shared/ui/animations';
import { LoanStatusEnum } from '../../types';
import { Chart } from './components/chart';
import { AlertLoansInfo } from './components/alertLoansInfo';
import { AmountLoansInfo } from './components/amountLoansInfo';
import styles from './style.module.scss';
import { Loan } from 'api/endpoints/loans';
import { useLaonDismissMutation } from 'api/endpoints/loans';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
interface LoansInfoProps {
  loans: Loan;
  signUrl: string;
}
export const LoansInfo = ({ loans, signUrl }: LoansInfoProps) => {
  const { setSnackBar } = useSnackBar();

  const [laonDismis, { isLoading: isLoadingLaonDismis }] =
    useLaonDismissMutation();

  const statusRepaid = LoanStatusEnum.Repaid === loans?.status;
  const viewDismissBtn = LoanStatusEnum.Defaulted !== loans?.status;
  const isDisable = LoanStatusEnum.WrittenOff === loans?.status;

  const dismis = async () => {
    try {
      await laonDismis(true).unwrap();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <AlertLoansInfo
          status={loans?.status}
          signUrl={signUrl}
          statusRepaid={statusRepaid}
        />
        {!statusRepaid && (
          <>
            <div className={styles.box}>
              <Chart loans={loans} />
              <AmountLoansInfo loans={loans} />
            </div>
            <div className={styles.boxBtn}>
              <Button
                variant='text'
                onClick={() => window.open(signUrl, '_blank')}
                disabled={isDisable}
              >
                View details
              </Button>
              {viewDismissBtn && (
                <Button
                  variant='text'
                  onClick={dismis}
                  loading={isLoadingLaonDismis}
                >
                  Dismiss
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </TransitionAnimation>
  );
};
