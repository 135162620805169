import ewaIcon from 'assets/svg/switchAceessShortcut.svg';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';

export const EwaEmpty = () => {
  return (
    <div className={styles.wrapper}>
      <img
        src={ewaIcon}
        className={styles.icon}
      />
      <Typography>Earned Wage Access is not connected</Typography>
      <Typography className={styles.subTitle}>
        Earned Wage Access information will be displayed here.
      </Typography>
    </div>
  );
};
