import { useMemo, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { SvgIcon } from 'shared/components/svgIcon';
import { colors } from 'theme';
import { replaceNumberWithComma } from 'shared/utils/price';
import {
  useRewardActivityQuery,
  RewardActivityEnum,
} from 'api/endpoints/rewards';
import {} from 'api/endpoints/rewards';
import {
  divideSegment,
  formatYLabel,
} from 'pages/home/components/diagrams/shared/common';
import moment from 'moment';
import styles from './style.module.scss';
import cn from 'classnames';
import { LinearProgress } from '@mui/material';
import { PeriodInfo } from './periodInfo';
import { DiagramTopPanel } from 'pages/home/components/diagrams/shared/diagramTopPanel';

export const RewardsActivity = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<RewardActivityEnum>(
    RewardActivityEnum.M6
  );
  const { data: rewardActivity, isFetching: isLoadingRewardActivity } =
    useRewardActivityQuery({ rewardActivity: selectedPeriod });

  const dataset = useMemo(() => {
    if (!rewardActivity?.data) {
      return [];
    }
    return rewardActivity.data.map((i) => {
      return {
        in: i.pointsIn,
        out: i.pointsOut,
        xAxisLabel: `${i.dateFrom}/${i.dateTo}`,
      };
    });
  }, [rewardActivity?.data]);

  const yAxisConfig: any = useMemo(() => {
    if (!rewardActivity?.data) {
      return undefined;
    }
    const maxPointsValue =
      rewardActivity?.data && !!rewardActivity.data.length
        ? Math.max(
            ...rewardActivity.data.flatMap((i) => [i.pointsIn, i.pointsOut])
          )
        : 0;

    const hasData = maxPointsValue > 0;
    const maxValue = hasData ? maxPointsValue : 1;
    const tickInterval = hasData ? divideSegment(0, maxValue) : [0];

    const valueFormatter = (value: number, context) => {
      if (
        hasData &&
        context.location === 'tick' &&
        typeof value === 'number' &&
        value > 0
      ) {
        return value <= 100
        ?
            formatYLabel(value, '')
          : formatYLabel(Math.round(value),'');
      }
      return '0';
    };

    return [
      {
        tickInterval,
        max: maxValue,
        min: 0,
        valueFormatter,
      },
    ];
  }, [rewardActivity?.data]);

  const xAxisConfig = useMemo(() => {
    const period = selectedPeriod;
    return [
      {
        scaleType: 'band',
        dataKey: 'xAxisLabel',
        categoryGapRatio: 0.18,
        barGapRatio: 0,
        valueFormatter: (value: string, context) => {
          if (isLoadingRewardActivity) {
            return '';
          }

          const [dateFrom, dateTo] = value.split('/');
          const mdf = moment(dateFrom);

          if (context.location === 'tick') {
            if (
              [
                RewardActivityEnum.M6,
                RewardActivityEnum.Y1,
                RewardActivityEnum.QTD,
                RewardActivityEnum.YTD,
              ].includes(period)
            ) {
              return mdf.format('MMM');
            }
          }

          if (context.location === 'tooltip') {
            if (
              [
                RewardActivityEnum.M6,
                RewardActivityEnum.Y1,
                RewardActivityEnum.QTD,
                RewardActivityEnum.YTD,
              ].includes(period)
            ) {
              return mdf.format('MMMM, YYYY');
            }
          }

          return dateFrom;
        },
      } as any,
    ];
  }, [rewardActivity?.data, selectedPeriod, isLoadingRewardActivity]);

  return (
    <div className={styles.wrapper}>
      <DiagramTopPanel
        categories={['Rewards activity']}
        selectedCategory='Rewards activity'
        isDisabled={isLoadingRewardActivity}
        onChangePeriod={(newPeriod) => setSelectedPeriod(newPeriod as any)}
        selectedPeriod={selectedPeriod}
        periods={[
          { label: '6M', value: RewardActivityEnum.M6 },
          { label: 'QTD', value: RewardActivityEnum.QTD },
          { label: '1Y', value: RewardActivityEnum.Y1 },
          { label: 'YTD', value: RewardActivityEnum.YTD },
        ]}
        onChangeCategory={(newCategory) => {}}
      />
      <div className={styles.chartWrapper}>
        <div
          className={cn(
            styles.loader,
            isLoadingRewardActivity && styles.loaderActive
          )}
        >
          <LinearProgress />
        </div>
        <PeriodInfo
          selectedPeriod={selectedPeriod}
          total={rewardActivity?.total}
        />
        {rewardActivity?.data?.length && (
          <>
            <BarChart
              sx={{
                '.MuiChartsAxis-line': {
                  display: 'none',
                },
                '.MuiChartsAxis-tick': {
                  display: 'none',
                },
              }}
              dataset={dataset}
              series={[
                {
                  type: 'bar',
                  dataKey: 'in',
                  label: 'Points In',
                  color: colors.green,
                    valueFormatter: (v) => replaceNumberWithComma(v ?? 0).toString(),

                },
                {
                  type: 'bar',
                  dataKey: 'out',
                  label: 'Points Out',
                  color: colors.lightGrey,
                    valueFormatter: (v) => replaceNumberWithComma(v ?? 0).toString(),
                },
              ]}
              height={110}
              xAxis={xAxisConfig}
              yAxis={yAxisConfig}
              slotProps={{ legend: { hidden: true } }}
              grid={{ horizontal: true }}
              borderRadius={4}
              margin={{ top: 10, bottom: 25, left: 40, right: 5 }}
            />
            <div className={styles.hint}>
              <SvgIcon
                name='InfoOutlined'
                sizePx={19}
              />
              <div>
                Points are awarded on the 1st through the 10th day of the month
                for the previous month.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
