import { Button } from "shared/ui/buttons";
import { Typography } from "@mui/material";
import { useBoolean } from "shared/hooks/useBoolean";
import { SvgIcon } from "shared/components/svgIcon";

import styles from './style.module.scss'
import DrawerManageExternalAccount from "./components/DrawerManageExternalAccount";

const ManageExtrenalAccount = () => {
  const isShowDrawer = useBoolean()

  return (
    <>
      <Button variant="text" className={styles.btn} onClick={isShowDrawer.setTrue}>
        <SvgIcon name="Settings" /> <Typography>manage</Typography>
      </Button>
      <DrawerManageExternalAccount 
        isShow={isShowDrawer.value}
        onClose={isShowDrawer.setFalse}
      />
    </>
  )
}

export default ManageExtrenalAccount;