import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../baseApi';
import { ApiTags } from 'api/api-tags';
import { SecureOperationType } from 'enums';
interface EarnedWageAccessStatus {
  isActive: boolean;
  frequency: number;
  userOwnsMoney: boolean;
  ewaAmount: number;
}
export interface GetEarnedWageAccessApi {
  connectedFinancialAccountId: string | null;
  maxEwaAmount: number;
  nextPayDate: string;
  ewaPayDate: string;
  fee: number;
  currentStatus: EarnedWageAccessStatus;
}
interface SaveEarnedWageAccessArg {
  active: boolean;
  frequency?: number;
  ewaAmount?: number;
  oneTimeCode?: string;
  secureOperationType?: SecureOperationType;
}
export const pinWheelApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'pinWheelApi',
  tagTypes: [ApiTags.GetPinWheelToken, ApiTags.GetEwa],
  endpoints: (builder) => ({
    getPinWheelToken: builder.query({
      query: (financialAccountId) => ({
        url: `api/PinW/pinToken?financialAccountId=${financialAccountId}`,
        method: 'GET',
      }),
    }),
    getEarnedWageAccess: builder.query<GetEarnedWageAccessApi, void>({
      providesTags: [ApiTags.GetEwa],
      query: () => ({
        url: 'api/PinW/earnedWageAccess',
        method: 'GET',
      }),
    }),
    saveEarnedWageAccess: builder.mutation<any, SaveEarnedWageAccessArg>({
      invalidatesTags: [ApiTags.GetEwa],
      query: (queryArg) => ({
        url: 'api/PinW/earnedWageAccess',
        method: 'POST',
        body: queryArg,
      }),
    }),
  }),
});

export const {
  useLazyGetPinWheelTokenQuery,
  useGetEarnedWageAccessQuery,
  useSaveEarnedWageAccessMutation,
} = pinWheelApi;
