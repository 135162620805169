import { DeliveryServiceCard } from 'entities/delivery-service';
import { Typography } from 'shared/ui/typography';
import { TextInput } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';
import { FullAddress, FullName } from 'pages/cardManage/types';
import { formatAddress } from 'shared/lib/format';
import {
  useReissueCardMutation,
  ReissueReason,
} from 'api/endpoints/paymentCards';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackBar } from 'providers/snackBar.provider';
import { appRoutes } from 'routes';
import helper from 'services/helper';
import { CountryCodeAlpha3 } from 'enums';
import { DeliveryMethod } from 'api/endpoints/paymentCards';
import { useCardInformation } from 'pages/cardManage/provider';
import { useHolderV2ControllerQuery } from 'api/endpoints/account';
import { TransitionAnimation } from 'shared/ui/animations';
import { Skeleton } from 'components/skeleton';
import { OPEN_NEW_CARD_SLEEP_MS } from 'shared/constants';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import { useBoolean } from 'shared/hooks/useBoolean';

interface PhysicalCardConfirmProps {
  fullAddress: FullAddress;
  deliveryService: string;
  fullName: FullName;
  selectedReason: ReissueReason | null;
  darawerOnClose: () => void;
}

export const PhysicalCardConfirm = (props: PhysicalCardConfirmProps) => {
  const {
    fullAddress,
    deliveryService,
    fullName,
    selectedReason,
    darawerOnClose,
  } = props;
  const navigate = useNavigate();
  const params = useParams();
  const paymentCards = usePaymentCards();
  const { setSnackBar } = useSnackBar();
  const { currentCard } = useCardInformation();
  const { data: holderData, isLoading: isLoadingHolder } =
    useHolderV2ControllerQuery();
  const [reissueCard] = useReissueCardMutation();

    const loadingReissueCardBool =useBoolean()

  const financialAccountId = params.accountId;

  const reissuePhysicalCard = async () => {
    try {
        loadingReissueCardBool.setTrue()
      const legalBusinessName =
        holderData?.businessAccountHolder?.legalBusinessName;
      if (selectedReason && financialAccountId && legalBusinessName) {
        await reissueCard({
          paymentCardId: currentCard.id,
          reason: selectedReason,
          orderPhysicalPaymentCard: {
            deliveryDetails: {
              name: {
                givenName: fullName.givenName,
                familyName: fullName.familyName,
              },
              companyName: legalBusinessName,
              address: {
                streetAddress: fullAddress.streetAddress,
                extendedAddress: fullAddress.additionalAddress,
                postalCode: fullAddress.postalCode,
                region: fullAddress.region,
                locality: fullAddress.locality,
                countryCodeAlpha3: CountryCodeAlpha3.USA,
              },
            },
            courier: {
              method: deliveryService as DeliveryMethod,
            },
          },
        }).unwrap();
        await helper.sleep(OPEN_NEW_CARD_SLEEP_MS);
      paymentCards.paymentCardsRefetch();
      loadingReissueCardBool.setFalse()
        setSnackBar({
          type: 'success',
          message:
            'Your card has been successfully re-issued. You can now use it for all your banking needs without interruption.',
          isShow: true,
          width: '400px'
        });
        darawerOnClose();
        navigate(appRoutes.accountById(financialAccountId));
      }
    } catch (e: any) {
        loadingReissueCardBool.setFalse()
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  return isLoadingHolder ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.deliveryCard}>
          <Typography variant='subtitle1'>Delivery service</Typography>

          <DeliveryServiceCard serviceTypeId={deliveryService} />
        </div>

        <div className={styles.shipAddress}>
          <Typography variant='subtitle1'>Shipping address</Typography>

          <TextInput
            fullWidth
            InputProps={{ readOnly: true }}
            value={formatAddress(fullAddress, fullAddress.additionalAddress)}
          />
        </div>

        <Button
          className={styles.btn}
          onClick={reissuePhysicalCard}
          loading={loadingReissueCardBool.value}
        >
          PLACE ORDER
        </Button>
      </div>
    </TransitionAnimation>
  );
};
