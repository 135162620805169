import { AnimateWrapper } from 'components/animate-wrapper';
import { Typography } from 'shared/ui/typography';
import { CasesOutlinedIcon } from 'shared/ui/icons';
import { RadioGroup, FormHelperText } from '@mui/material';
import { Radio } from 'shared/ui/inputs';
import { EwaFrequencyTiltle } from 'pages/settings/accountSettings/types';
import { CurrencyInput2 } from 'shared/ui/inputs';
import { Button } from 'shared/ui/buttons';
import helper from 'services/helper';
import { useFormik } from 'formik';
import {
  getEwaFrequencyText,
  getEwaFrequencyNumber,
} from 'pages/settings/accountSettings/lib';
import moment from 'moment';
import { useEwa } from 'providers/ewa';
import { useSnackBar } from 'providers/snackBar.provider';
import { useSaveEarnedWageAccessMutation } from 'api/endpoints/pinWheel';
import { SecureOperationType, ShortMessageType } from 'enums';
import { useMfa } from 'providers/mfa/mfa';
import * as yup from 'yup';
import TooltipChildren from 'shared/components/toolTip/toolTipChildren';
import { Skeleton } from 'components/skeleton';
import styles from './styles.module.scss';

const forEveryFutureText =
  'the next payment will be processed 3 days before payday, and the deposited amount will be deducted from your account on the upcoming payday ';

const forThisTime = `payment will be processed upon confirmation of your request.\n\nThe deposited amount will be deducted from your account on the next payday, scheduled for `;

const forThisTimeToolTip =
  'For this time only” option cannot be selected as the transfer is only available within 3 days or less before payday';

export const createValidateSchema = (maxAmount: number) => {
  const errorMessage = `The available amount range is from $0 to $${helper.moneyFormat(
    maxAmount
  )}. To expand the Earned Wage Access capabilities, set the amount to the full paycheck.`;

  return yup.object().shape({
    type: yup.string().required(),
    amount: yup
      .string()
      .required(errorMessage)
      .test('amount-check', errorMessage, function (value) {
        const amountValue = Number(value);
        if (amountValue > maxAmount || amountValue == 0) {
          return false;
        }
        return true;
      }),
  });
};

interface FormValue {
  type: string;
  amount: string;
}
export const EwaForm = () => {
  const mfa = useMfa();
  const { setSnackBar } = useSnackBar();
  const { data, connectedAccountName, hide, isLoading } = useEwa();
  const [saveEwaMutation] = useSaveEarnedWageAccessMutation();

  const status = data?.currentStatus?.isActive;
  const labelBtn = status ? 'save changes' : 'confirm request';
  const date = moment(data?.nextPayDate).format('MM/DD/YYYY');

  const showForThisTime = moment().isBetween(
    moment(data?.ewaPayDate),
    moment(data?.nextPayDate),
    undefined,
    '()'
  );

  const saveEwa = async (
    form: FormValue,
    secureOperationType: SecureOperationType,
    code: string
  ) => {
    try {
      await saveEwaMutation({
        active: true,
        frequency: getEwaFrequencyNumber(form.type),
        ewaAmount: Number(form.amount),
        oneTimeCode: code,
        secureOperationType,
      }).unwrap();
      setSnackBar({
        type: 'success',
        width: '400px',
        message:
          'Earned Wage Access has been successfully requested. You will have access to the funds in your account shortly.',
        isShow: true,
      });

      mfa.close();
      hide();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const getInitialType = () =>
    status
      ? getEwaFrequencyText(data?.currentStatus.frequency)
      : EwaFrequencyTiltle.ForEveryFuturePaycheck;

  const getInitialAmount = () =>
    status ? String(data?.currentStatus.ewaAmount) : '';

  const formik = useFormik({
    initialValues: {
      type: getInitialType(),
      amount: getInitialAmount(),
    },
    validateOnMount: false,
    validationSchema: createValidateSchema(data?.maxEwaAmount!),
    onSubmit: (form) => {
      mfa.show({
        title: 'Confirm Action',
        shortMessageType: ShortMessageType.SetEwaRequest,
        isOnlySmsFlow: true,
        async onCodeSubmit(
          secureOperationType: SecureOperationType,
          code: string
        ) {
          await saveEwa(form, secureOperationType, code);
        },
      });
    },
  });

  const renderLabelRadioForThisTimeOnly = () => {
    if (showForThisTime) {
      return <Typography>{EwaFrequencyTiltle.ForThisTimeOnly}</Typography>;
    }

    return (
      <TooltipChildren description={`${forThisTimeToolTip} ${date}.`}>
        <div>
          <Typography className={styles.forThisTimeOnlyDisabled}>
            {EwaFrequencyTiltle.ForThisTimeOnly}
          </Typography>
        </div>
      </TooltipChildren>
    );
  };

  return isLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <AnimateWrapper className='fade'>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.boxType}>
          <div className={styles.connected}>
            <CasesOutlinedIcon />
            <div>
              <Typography>Connected direct deposit account</Typography>
              <Typography>{connectedAccountName}</Typography>
            </div>
          </div>

          <div className={styles.radio}>
            <Typography>Earned Wage Access type:</Typography>
            <RadioGroup
              className={styles.radioGroup}
              {...formik.getFieldProps('type')}
            >
              <Radio
                key={EwaFrequencyTiltle.ForThisTimeOnly}
                LabelProps={{ className: styles.radioItem }}
                value={EwaFrequencyTiltle.ForThisTimeOnly}
                label={renderLabelRadioForThisTimeOnly()}
                disabled={!showForThisTime}
              />
              <Radio
                key={EwaFrequencyTiltle.ForEveryFuturePaycheck}
                LabelProps={{ className: styles.radioItem }}
                value={EwaFrequencyTiltle.ForEveryFuturePaycheck}
                label={
                  <Typography>
                    {EwaFrequencyTiltle.ForEveryFuturePaycheck}
                  </Typography>
                }
              />
            </RadioGroup>
          </div>
        </div>
        <div className={styles.boxAmount}>
          <Typography>Amount you will receive in advance:</Typography>
          <div>
            <CurrencyInput2
              fullWidth
              variant='outlined'
              label='Amount'
              placeholder='$0.00'
              name='amount'
              value={formik.values.amount}
              onChange={(value) => {
                formik.setFieldTouched('amount', true);
                formik.setFieldValue('amount', value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.amount && formik.errors.amount}
              getCaretBoundary={() => [false]}
            />
            {!formik.errors.amount && (
              <FormHelperText className={styles.helterText}>
                The available amount range is from $0 to $
                {helper.moneyFormat(data?.maxEwaAmount)}
              </FormHelperText>
            )}
          </div>
          <Typography>Fee: ${helper.moneyFormat(data?.fee)}</Typography>
        </div>
        {formik.values.type && (
          <div className={styles.info}>
            <Typography className={styles.infoTextNote}>
              <span className={styles.infoBoldNote}>Note: </span>
              {formik.values.type == EwaFrequencyTiltle.ForThisTimeOnly
                ? forThisTime
                : forEveryFutureText}
              {date}.
            </Typography>
          </div>
        )}
        <Button
          type='submit'
          className={styles.btn}
          disabled={!formik.isValid || !formik.dirty}
        >
          {labelBtn}
        </Button>
      </form>
    </AnimateWrapper>
  );
};
