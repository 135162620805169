import React from 'react';
import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { PaymentCard } from 'api/endpoints/paymentCards';
import { CardView } from 'shared/components/CardView';
import { Typography } from 'shared/ui/typography';
import { Badge as Chip } from 'shared/ui/badge';
import { Divider } from '@mui/material';
import Badge from 'pages/accountComponent/components/Badge';
import helper from 'services/helper';
import styles from './style.module.scss';
import cn from 'classnames';
import { Skeleton } from 'components/skeleton';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { EmptyItem } from '../emptyItem';
import { usePermission } from 'shared/hooks/usePermission';

interface TableMobProps {
  cards: PaymentCard[];
  cadsIsLoading: boolean;
}
export const TableMob = ({ cards, cadsIsLoading }: TableMobProps) => {
  const navigate = useNavigate();
  const [_, setSearchParams] = useSearchParams();
  const { canOpenCard } = usePermission();

  const handleNavigateToCard = (accountId: string | null, cardId: string) => {
    if (accountId) {
      navigate(appRoutes.cardById(accountId, cardId));
    }
  };

  const renderTableItem = (card: PaymentCard) => {
    const name = card.cardName !== null ? card.cardName : 'Business Debit';

    const cardHolder =
      card.subProfile !== null ? card.subProfile : card.cardholder;

    const formattedFormFactor = card.formFactor
      ? helper.capitalizeFirstLetter(card.formFactor.toString())
      : '';

    return (
      <div
        key={card.id}
        className={styles.item}
        onClick={() =>
          handleNavigateToCard(card.financialAccounts[0]?.id, card.id)
        }
      >
        <CardView
          isSmallSize
          card={card}
        />
        <div className={styles.info}>
          <div className={styles.box}>
            <p className={styles.name}>
              {name} <br /><span className={styles.text}>(****{card.last4 || 'XXXX'})</span>
            </p>
            <Typography className={styles.text}>
              {formattedFormFactor}
            </Typography>
            {card.subProfile && (
              <div className={styles.boxBadgeSub}>
                <Chip label='Authorized user' />
              </div>
            )}
          </div>
          <div className={cn(styles.end, styles.box)}>
            <Typography className={styles.text}>{cardHolder}</Typography>
            {card.department && (
              <Typography className={styles.text}>{card.department}</Typography>
            )}
            {card.isMainCard && (
              <Chip
                styledWrapper={styles.chipPrimary}
                label='Primary Card'
              />
            )}
            <Badge item={card} />
          </div>
        </div>
      </div>
    );
  };
  const renderContent = () =>
    !!cards?.length ? (
      cards?.map((card, index) => (
        <React.Fragment key={card.id}>
          {renderTableItem(card)}
          {index !== cards.length - 1 && <Divider className={styles.divider} />}
        </React.Fragment>
      ))
    ) : (
      <EmptyItem />
    );
  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {canOpenCard && (
            <div className={styles.boxBtn}>
              <Button
                variant='text'
                onClick={() => setSearchParams({ mode: 'openCard' })}
                disabled={cadsIsLoading}
              >
                + Open new card
              </Button>
            </div>
          )}
          {cadsIsLoading ? (
            <Skeleton
              width='100%'
              height='200px'
            />
          ) : (
            renderContent()
          )}
        </div>
      </div>
    </TransitionAnimation>
  );
};
