import { ExternalAccountBalanceResponse, useLazyExternalAccountBalanceQuery } from "api/endpoints/financialAccounts";
import { PropsWithChildren, createContext, useCallback, useContext, useMemo, useState } from "react";

export interface ExternalAccountBalance extends Partial<ExternalAccountBalanceResponse> {
  id?: string;
  isLoading: boolean;
  error: string | null;
}

type ExternalAccountBalanceValue = {
  balance: Record<string, ExternalAccountBalance> | null,
  fetchBalance: (accountId: string) => Promise<void>
  clearBalances: () => void
  selectBalance: (accountId: string) => ExternalAccountBalance | null
  removeBalance: (accountId: string) => void
}

const initialValue = {
  balance: null,
  fetchBalance: async () => {},
  clearBalances: () => {},
  selectBalance: () => null,
  removeBalance: () => {},
}

const ExternalAccountsBalanceContext = createContext<ExternalAccountBalanceValue>(initialValue);

export const ExternalAccountsBalanceProvider = ({ children }: PropsWithChildren) => {
  const [balance, setBalance] = useState<ExternalAccountBalanceValue['balance']>(null);
  const [query] = useLazyExternalAccountBalanceQuery();
  
  const fetchBalance = useCallback(async (accountId: string) => {
    setBalance(prev => {
      return {
        ...prev,
        [accountId]: {
          isLoading: true,
          error: null,
        }
      }
    })

    const responce = await query(accountId); 
    const { data, isSuccess } = responce

    const err = isSuccess ? null : 'Failed to load data';
    const value = data?.value && data.value * 100;
    
    setBalance(prev => {
      return {
        ...prev,
        [accountId]: {
          ...data,
          value,
          isLoading: false,
          error: err,
        }
      }
    })
  }, []);

  const value = useMemo(() => ({
    fetchBalance: fetchBalance,
    balance: balance,
    removeBalance: (accountId: string) => {
      setBalance((prev) => {
        delete prev?.[accountId];

        return {...prev}
      })
    }, 
    clearBalances: () => setBalance(null),
    selectBalance: (accountId: string) => {
      return balance?.[accountId] ?? null
    }
  }), [balance]);
  
  return (
    <ExternalAccountsBalanceContext.Provider value={value}>
      {children}
    </ExternalAccountsBalanceContext.Provider>
  );
};

export const useExternalAccountsBalance = () => useContext(ExternalAccountsBalanceContext);
