import { Button } from 'shared/ui/buttons'
import { ChevronRightIcon } from 'shared/ui/icons'
import { PersonWithCircleIcon } from 'shared/ui/icons'
import { Typography } from 'shared/ui/typography'
import styles from './styles.module.scss'
import moment from 'moment'

interface INeteviaPayee {
  id: string
  accountNumber: string
  name: string
  handleNavigate: (id: string) => void
  createdAt: string;
}

export const NeteviaPayee = (props: INeteviaPayee) => {
  const { id, name, accountNumber, handleNavigate, createdAt } = props

  return (
    <Button
      className={styles.btn}
      key={id}
      startIcon={<PersonWithCircleIcon className={styles.icon} />}
      endIcon={<ChevronRightIcon />}
      onClick={() => handleNavigate(id)}
    >
      <div className={styles.title}>
        <Typography>{name}</Typography>
        <Typography>(**** {accountNumber.slice(-4)})</Typography>
        <Typography>Created on: {moment(createdAt).format('MM/DD/YYYY')}</Typography>
      </div>
    </Button>
  )
}
