import { TransitionAnimation } from 'shared/ui/animations';
import { Accordion } from '../accordion';
import { FormControlLabel, Checkbox } from '@mui/material';
import styles from './style.module.scss';
import { FilterProps } from 'pages/accountComponent/types';
import { Skeleton } from 'components/skeleton';

const selectAll = 'Select All';

export const FilterByMerchantType = (props: FilterProps) => {
  const { filterObj, setFilterObj, loading, expandFilter, setExpandFilter } =
    props;

  const { expandByMerchantType } = expandFilter;

  const transactionType = filterObj.pos?.items || [];

  const handleChangeExpand = (value: boolean) => {
    setExpandFilter({
      ...expandFilter,
      expandByMerchantType: value,
    });
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const updatedItems =
      filterObj.pos.items?.map((item) => {
        if (item.displayName === name) {
          return { ...item, value: checked };
        }
        return item;
      }) || null;

    const updatedFilters = {
      ...filterObj,
      pos: {
        ...filterObj.pos,
        items: updatedItems,
      },
    };

    setFilterObj(updatedFilters);
  };


  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    const updatedItems = transactionType.map((item) => ({
      ...item,
      value: checked,
    }));

    const updatedFilters = {
      ...filterObj,
      pos: {
        ...filterObj.pos,
        items: updatedItems,
      },
    };

    setFilterObj(updatedFilters);
  };

  const renderCheckBox = () => (
      <>
        <FormControlLabel
          classes={{ root: styles.rootFormControlLabel }}
          control={
            <Checkbox
              classes={{
                checked: styles.checked,
              }}
              checked={transactionType.every(item => item.value)} 
              onChange={handleSelectAllChange}
            />
          }
          label={selectAll}
        />
        
        {transactionType.map((item) => (
          <FormControlLabel
            key={item?.displayName}
            classes={{ root: styles.rootFormControlLabel }}
            control={
              <Checkbox
                classes={{
                  checked: styles.checked,
                }}
                checked={item?.value}
                onChange={handleCheckBoxChange}
                name={item?.displayName}
              />
            }
            label={item?.displayName}
          />
        ))}
      </>
    );
 

  return loading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <Accordion
        expanded={expandByMerchantType ?? false}
        onChangeExpanded={handleChangeExpand}
        summary='By merchant category'
      >
        {renderCheckBox()}
      </Accordion>
    </TransitionAnimation>
  );
};
