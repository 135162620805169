import { FC } from "react";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import exclamationIcon from "assets/svg/exclamation-mark-blue.svg";
import TooltipChildren from "shared/components/toolTip/toolTipChildren";
import { Error } from "pages/home/components/externalAccounts/components/ExternalAccountBalance";
import { Circle, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { getBalance } from "shared/utils/getBalance";
import styles from "./style.module.scss";
import { useExternalAccountsBalance } from "providers/externalAccountsBalanceProvider";

interface ItemBalanceProps {
  accountId: string;
}

const AVAILABLE_BALANCE_TOOLTIP = "The amount of money in your account that you can use right now.";

const Balance: FC<ItemBalanceProps> = ({ accountId }) => {
  const { fetchBalance, selectBalance, removeBalance  } = useExternalAccountsBalance();
  const balance = selectBalance(accountId);

  const handleShowClick = () => {
    fetchBalance(accountId);
  };

  const handleHideClick = () => {
    removeBalance(accountId);
  };

  if (!balance) {
    return (
      <Box>
        <Box display="flex" gap="8px" alignItems="center">
          <Typography variant="h6" style={{ fontWeight: 600 }}>$</Typography>
          {new Array(6).fill(0).map((_, index) => (
            <Circle key={index} style={{ width: "8px", color: "rgba(0, 0, 0, 0.3)" }} />
          ))}
          <IconButton
            aria-label="show balance"
            edge="end"
            style={{ color: "var(--color-secondary-main)" }}
            onClick={handleShowClick}
          >
            <VisibilityOutlined />
          </IconButton>
        </Box>
      </Box>
    );
  }

  if (balance.isLoading) {
    return <Skeleton height={48} width={148} />;
  }

  if (balance.error) {
    return (
      <Box display="flex" alignItems="center">
        <Error />
        <IconButton
          aria-label="hide balance"
          edge="end"
          style={{ color: "var(--color-secondary-main)" }}
          onClick={handleHideClick}
        >
          <VisibilityOffOutlined />
        </IconButton>
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography className={styles.balanceText}>
          {getBalance(balance.value, balance.currency)}
        </Typography>
        <IconButton
          aria-label="hide balance"
          edge="end"
          style={{ color: "var(--color-secondary-main)" }}
          onClick={handleHideClick}
        >
          <VisibilityOutlined />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" gap={'4px'}>
        <Typography className={styles.descriptionText}>Available Balance</Typography>
        <TooltipChildren description={AVAILABLE_BALANCE_TOOLTIP}>
          <img src={exclamationIcon} alt="tooltip icon" />
        </TooltipChildren>
      </Box>
    </>
  );
};

const ItemBalance: FC<ItemBalanceProps> = ({ accountId }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.box}>
        <Balance accountId={accountId} />
      </div>
    </div>
  );
};

export default ItemBalance;
