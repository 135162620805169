import { ExternalAccount, useExternalAccountsQuery } from 'api/endpoints/financialAccounts';

const filteredAccounts = (financialAccounts: ExternalAccount[]) => 
  financialAccounts.filter((fa) => fa.accountStatus !== 'CLOSED' && fa.provider !== null)

export const useExternalAccounts = () => {
  const { data = [], isLoading: accountsLoading } = useExternalAccountsQuery();

  return {
    financialAccounts: filteredAccounts(data),
    isLoading: accountsLoading,
  };
};
