import { TransitionAnimation } from 'shared/ui/animations';
import { useRewardIntegrations } from 'api/hooksApi/useRewardsIntegrations';
import { Place } from 'api/endpoints/rewardIntegrations';
import styles from './styles.module.scss';
import { SliderRewards } from 'pages/reward/components/dashboard/components/featuredRewards/componetns/sliderRewards';
import { useRewardIntegrationsHandler } from 'providers/rewardIntegrationsHandler';
import cx from 'classnames';

const SLIDER_LENGTH = 1;

export const HomeSlider = () => {
  const { rewardIntegrations, rewardIntegrationsIsLoading } =
    useRewardIntegrations({ place: Place.Home });

  const rewardIntegrationsHandler = useRewardIntegrationsHandler();

  const settingsSlider = {
    dots: false,
    speed: 500,
    slidesToShow: SLIDER_LENGTH,
    slidesToScroll: 1,
    arrows: false,
  };

  const isMoreSliderLen = rewardIntegrations.length > SLIDER_LENGTH;

  if (rewardIntegrations.length === 0) {
    return null;
  }

  return (
    <TransitionAnimation>
      <SliderRewards
        data={rewardIntegrations}
        loadingData={rewardIntegrationsIsLoading}
        disabledPrevSlide={!isMoreSliderLen}
        disabledNextSlide={!isMoreSliderLen}
        sliderHandler={rewardIntegrationsHandler.handle}
        settingsSlider={settingsSlider}
        styleWrapper={styles.wrapper}
        styleWrapperHeader={cx(
          styles.styledHeader,
          !isMoreSliderLen && styles.headerDisplayNone
        )}
        styleItem={{
          wrapper: styles.styledItemWrapper,
          item: styles.styledItem,
        }}
        infinite={isMoreSliderLen}
        autoplay={isMoreSliderLen}
      />
    </TransitionAnimation>
  );
};
