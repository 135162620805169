import { LoadingButton } from '@mui/lab';
import { Typography } from 'shared/ui/typography';
import { TextInput } from 'shared/ui/inputs';
import { InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { FormikProps } from 'formik';
import { useBoolean } from 'shared/hooks/useBoolean';
import styles from './style.module.scss';

export interface FormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ChangePasswordFormProps {
  formik: FormikProps<FormValues>;
  loading: boolean;
}

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const { formik, loading } = props;
  const { t } = useTranslationProvider();

  const passwordBool = useBoolean();
  const newAndConfirmPasswordBool = useBoolean();

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={styles.wrapper}
      noValidate
      autoComplete='off'
    >
      <Typography>
        {t('Settings.Enter your current password', {
          defaultValue: 'Enter your current password',
        })}
      </Typography>

      <TextInput
        fullWidth
        label={t('Login.Password', {
          defaultValue: 'Password',
        })}
        type={passwordBool.value ? 'text' : 'password'}
        {...formik.getFieldProps('currentPassword')}
        error={formik.touched.currentPassword && formik.errors.currentPassword}
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => passwordBool.toggle()}
                edge='end'
              >
                {passwordBool.value ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography>
        {t('Login.Create new password', {
          defaultValue: 'Create new password',
        })}
      </Typography>

      <TextInput
        fullWidth
        label={t('Settings.New password', {
          defaultValue: 'New password',
        })}
        type={newAndConfirmPasswordBool.value ? 'text' : 'password'}
        {...formik.getFieldProps('newPassword')}
        error={formik.touched.newPassword && formik.errors.newPassword}
        autoComplete='off'
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={() => newAndConfirmPasswordBool.toggle()}
                edge='end'
              >
                {newAndConfirmPasswordBool.value ? (
                  <VisibilityOff />
                ) : (
                  <Visibility />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div>
        <TextInput
          fullWidth
          label={t('Login.Confirm password', {
            defaultValue: 'Confirm password',
          })}
          type={newAndConfirmPasswordBool.value ? 'text' : 'password'}
          {...formik.getFieldProps('confirmNewPassword')}
          error={
            formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword
          }
          autoComplete='off'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => newAndConfirmPasswordBool.toggle()}
                  edge='end'
                >
                  {newAndConfirmPasswordBool.value ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <LoadingButton
        type='submit'
        variant='contained'
        className={styles.btn}
        loading={loading}
        disabled={
          Object.values(formik.errors).some(Boolean) ||
          !Object.values(formik.touched).some(Boolean)
        }
      >
        {t('Login.Change Password', {
          defaultValue: 'Change Password',
        })}
      </LoadingButton>
    </form>
  );
};
