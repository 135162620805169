import { Button } from "@mui/material";
import { useDrawerStepperContext } from "shared/ui/drawer-stepper";
import { ChevronRightIcon } from "shared/ui/icons";

import styles from './styles.module.scss'

const ConnectedAccountHistoryButton = () => {
  const { goNext } = useDrawerStepperContext();

  return (
    <Button
      variant='text'
      endIcon={<ChevronRightIcon />}
      onClick={() => goNext('9')}
      className={styles.btnList}
    >
      connected account history
    </Button>
  )
}

export default ConnectedAccountHistoryButton;