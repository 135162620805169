import { PaymentCard } from 'api/endpoints/paymentCards';
import { usePaymentCards } from 'api/hooksApi/usePaymentCards';
import { useCurrentUser } from 'providers/user.provider';
import { useMemo } from 'react';
import { useSubUsersControllerQuery } from 'api/endpoints/subProfiles';

interface UseCheckPrimaryCardReturn {
  hasPrimaryCard: boolean;
  isLoadingHasPrimaryCard: boolean;
}

const filterActiveCard = (card: PaymentCard) => {
  return card.status !== 'CLOSED';
};

export const useCheckPrimaryCard = (
  subUserId?: string
): UseCheckPrimaryCardReturn => {
  const { user, isMainBusiness, isConsumer, isSubUser, userIsLoading } =
    useCurrentUser();
  const { paymentCards, paymentCardsIsFetching } = usePaymentCards();
  const { data: subUsers, isLoading: isLoadingSubUsers } =
    useSubUsersControllerQuery();

  const getUserFullName = (user) => `${user?.givenName} ${user?.familyName}`;

  const findPrimaryCardForSubUser = (subUserFullName: string) =>
    paymentCards
      .filter(
        (paymentCard) =>
          paymentCard.subProfile === subUserFullName &&
          filterActiveCard(paymentCard)
      )
      .some((card) => card.isMainCard);

  const hasPrimaryCard = useMemo(() => {
    if (subUserId) {
      const subUser = subUsers?.find(
        (user) => user.id.toString() === subUserId
      );
      const subUserFullName = getUserFullName(subUser);
      return findPrimaryCardForSubUser(subUserFullName);
    }

    if (isSubUser) {
      const subUserFullName = getUserFullName(user);
      return findPrimaryCardForSubUser(subUserFullName);
    }

    if (!subUserId && (isMainBusiness || isConsumer)) {
      return true;
    }

    return false;
  }, [paymentCards, subUserId, subUsers, user?.type]);

  return {
    hasPrimaryCard,
    isLoadingHasPrimaryCard:
      userIsLoading || paymentCardsIsFetching || isLoadingSubUsers,
  };
};
