import { Box, Skeleton, Typography } from "@mui/material";
import TooltipChildren from 'shared/components/toolTip/toolTipChildren';
import exclamationIcon from "assets/svg/exclamation-mark-blue.svg";
import { getBalance } from "shared/utils/getBalance";
import { useExternalAccountsBalance } from "providers/externalAccountsBalanceProvider";

import styles from './styles.module.scss';

const ERROR_MESSAGE = 'This data is currently unavailable due to external bank restrictions'

export const Error =() => {
  return (
    <Box display={'flex'} gap={'4px'} width={176}>
      <Typography className={styles.cellBalanceText}>
        No access to the details
      </Typography>

      <TooltipChildren description={ERROR_MESSAGE}>
        <img src={exclamationIcon} />
      </TooltipChildren>
    </Box>
  )
}

export const ExternalAccountBalance = ({ financialAccountId }: { financialAccountId: string }) => {
  const { selectBalance } = useExternalAccountsBalance();
  const balance = selectBalance(financialAccountId);

  if (!balance) {
    return <Box width={176}></Box> // so that the column doesn't jump
  }

  if (balance.isLoading) {
    return <Skeleton width={176} height={44} variant='rectangular' />
  }

  if (balance.error) {
    return <Error />
  }

  return (
    <Box width={176}>
      <Typography className={styles.cellBalanceText}>
        Available Balance
      </Typography>
      <Typography className={styles.cellBalanceCash}>
        {getBalance(balance.value, balance.currency)}
      </Typography>
    </Box>
  )
}