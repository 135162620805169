import { useExternalAccounts } from "api/hooksApi/useExternalAccounts";
import TableBlock from "shared/components/TableBlock";
import AddExternalAccount from "../AddExternalAccount";
import NoContent from "../NoContent";
import { useNavigateToExternal } from "pages/home/hooks";
import { AnimateWrapper } from "components/animate-wrapper";
import ButtonViewBalance from "../ButtonViewBalance";
import { ExternalAccountBalance } from "../ExternalAccountBalance";
import { ExternalAccount } from "api/endpoints/financialAccounts";
import ManageExtrenalAccount from "../ManageExternalAccounts";
import AccountItem from "../AccountItem";

import styles from '../../style.module.scss'

const tabCells = () => [
  {
    label: "Bank account",
    transform: (_: any, acc: ExternalAccount) => 
      <AccountItem account={acc} />
  },
  {
    label: "Available balance",
    name: "id",
    transform: (financialAccountId: string) => {
      return <ExternalAccountBalance financialAccountId={financialAccountId} />
    }
  },
  {
    name: 'id',
    props: { align: 'right' },
    transform: (financialAccountId: string) => {
      return <ButtonViewBalance financialAccountId={financialAccountId} />
    }
  }
]

export const ExternalAccountList = () => {
  const { financialAccounts } = useExternalAccounts();
  const { navigateToExternalAccount } = useNavigateToExternal();

  return (
    <AnimateWrapper className="fade">
      <div className={styles.container}>
        <TableBlock
          rows={financialAccounts}
          tabCells={tabCells()}
          title={<AddExternalAccount />}
          Action={ManageExtrenalAccount}
          NoContent={NoContent}
          onClick={navigateToExternalAccount}
          isRowsClickable
          hasMobileVersion={false}
          hideHeader
        />
      </div>
    </AnimateWrapper>
  )
}