import { Button } from "shared/ui/buttons"

import { useExternalAccountsBalance } from "providers/externalAccountsBalanceProvider";

import styles from './style.module.scss'

interface ButtonViewBalanceProps {
  financialAccountId: string;
}

const ButtonViewBalance = ({ financialAccountId }: ButtonViewBalanceProps) => {
  const { selectBalance, fetchBalance, removeBalance} = useExternalAccountsBalance()
  const balance = selectBalance(financialAccountId);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    fetchBalance(financialAccountId);
  };

  const handleHideClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    removeBalance(financialAccountId);
  }

  if (balance) {
    return (
      <Button 
      variant="text" 
      className={styles.btn} 
      onClick={handleHideClick}
    >
      Hide Balance
    </Button>
    )
  }

  return (
    <Button 
      variant="text" 
      className={styles.btn} 
      onClick={handleClick}
    >
      View Balance
    </Button>
  )
}

export default ButtonViewBalance;

