import { Popup } from 'components/popup';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import styles from './styles.module.scss';

interface ConnectDirectDipositModalProps {
  isShow: boolean;
  onClose: () => void;
  handleNavigate: () => void;
}

export const ConnectDirectDipositModal = ({
  isShow,
  onClose,
  handleNavigate,
}: ConnectDirectDipositModalProps) => {
  const handleDirDeposit = () => {
    handleNavigate();
    onClose();
  };

  return (
    <>
      {isShow && (
        <Popup
          onClose={onClose}
          stylesWrapper={styles.popup}
        >
          <div className={styles.text}>
            <Typography className={styles.textTitle}>
              Earned Wage Access
            </Typography>
            <Typography>
              To use Earned Wage Access, connect Direct Deposit full paycheck,
              percentage, or fixed amount.
              <br />
              <br />
              If you’ve already connected, please try again later.
            </Typography>
          </div>
          <div className={styles.btn}>
            <Button onClick={handleDirDeposit}>connect direct deposit</Button>
            <Button
              variant='text'
              onClick={onClose}
            >
              close
            </Button>
          </div>
        </Popup>
      )}
    </>
  );
};
