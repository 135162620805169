import { Typography } from "@mui/material";
import { SelectAccount } from "pages/accountComponent/Actions/Statements/components/selectAccount";
import { useState } from "react";
import styles from './styles.module.scss';
import LoadingButton from '@mui/lab/LoadingButton';
import PinwheelModal from "@pinwheel/react-modal";
import usePinWheelToken from "api/hooksApi/usePeenWheelToken";
import { useBoolean } from "shared/hooks/useBoolean";
import { useSnackBar } from "providers/snackBar.provider";
import { useFinancialAccounts } from "api/hooksApi/useFinancialAccounts";
import classNames from "classnames";
import ExpandableText from "../ExpandableText";
import { useGetEarnedWageAccessQuery } from "api/endpoints/pinWheel";

interface SwitchDirectDepositProps {
  financialAccountId?: string;
  onSuccess?: () => void;
  className?: string | string[];
}

const shortText = `<b>Important:</b> please note that changes to your settings may reset any existing Earned Wage Access configurations. Set your Direct Deposit to cover the full paycheck for the best access to your earned wages.`;
const fullText = 
  `<b>Important:</b> please note that changes to your settings may reset any existing Earned Wage Access configurations. Set your Direct Deposit to cover the full paycheck for the best access to your earned wages.\n
  If you select "Partial paycheck" > "Estimated" in your Direct Deposit Switch settings, Earned Wage Access will not be available.\n
  Making changes to your Direct Deposit Switch will reset your "Permanent" Earned Wage Access settings, requiring you to reconfigure them.`;

const SwitchDirectDeposit = ({ financialAccountId, onSuccess, className }: SwitchDirectDepositProps) => {
  const { refetch: ewaRefetch } = useGetEarnedWageAccessQuery();
  const { setSnackBar } = useSnackBar();
  const [currentAccountId, setCurrentAccountId] = useState<string>(financialAccountId ?? '');
  const { token, isLoading, getPinWheelToken } = usePinWheelToken();
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
  useFinancialAccounts()
  const isOpen = useBoolean();

  const handleClick = async () => {
    await getPinWheelToken(currentAccountId);
    isOpen.setTrue();
  };

  const handlChangeAccount = (id: string) => {
    setCurrentAccountId(id);
  };

  const handleSuccess = () => {
    ewaRefetch()
    setSnackBar({
      type: "success",
      message: 'Direct deposit successfully switched. You can change it anytime in the account settings.',
      isShow: true,
    });
    onSuccess?.();
  };

  const handleExit = () => {
    isOpen.setFalse();
  };

  const containerClassNames = classNames(styles.container, className);

  return (
    <div className={containerClassNames}>
      <Typography>Select the account for direct deposit</Typography>
      <SelectAccount
        value={currentAccountId}
        onChange={handlChangeAccount}
        showAll={false}
        loading={financialAccountsIsLoading}
        accounts={unclosedFinancialAccounts}
      />

      <ExpandableText shortText={shortText} fullText={fullText}/>
      <LoadingButton
        variant="contained"
        onClick={handleClick}
        disabled={!currentAccountId}
        loading={isLoading}
      >
        Next
      </LoadingButton>
     
      {isOpen.value && 
        <PinwheelModal 
          linkToken={token} 
          open={isOpen.value}
          onSuccess={handleSuccess}
          onExit={handleExit} 
        />
      }
    </div>
  );
}

export default SwitchDirectDeposit;
