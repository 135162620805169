import {
  DocumentUploadRequirementApi,
  RequiredDocumentStatus,
} from '../../api/endpoints/account';

export const allDocsIsSubmitted = (data: DocumentUploadRequirementApi) => {
  return Object.keys(data)
    .flatMap(
      (key) => data[key as keyof DocumentUploadRequirementApi]?.requiredDocuments || []
    )
    .every((doc) => doc.status === RequiredDocumentStatus.SUBMITTED);
};
