import { Button } from "shared/ui/buttons"
import AddIcon from '@mui/icons-material/Add'
import { Typography } from "@mui/material";
import { useBoolean } from "shared/hooks/useBoolean";
import DrawerAddExternalAccount from "./components/DrawerAddExternalAccount";

import styles from './style.module.scss'

const AddExternalAccount = () => {
  const isShowDrawer = useBoolean()

  return (
    <>
      <Button variant="text" className={styles.btn} onClick={isShowDrawer.setTrue}>
        <AddIcon /> <Typography>add account</Typography>
      </Button>
      <DrawerAddExternalAccount 
        isShow={isShowDrawer.value} 
        onClose={isShowDrawer.setFalse} 
      />
    </>

  )
}

export default AddExternalAccount;