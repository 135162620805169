import { AnimateWrapper } from 'components/animate-wrapper';
import { Typography } from 'shared/ui/typography';
import { Divider } from '@mui/material';
import { getEwaFrequencyText } from 'pages/settings/accountSettings/lib';
import moment from 'moment';
import helper from 'services/helper';
import styles from './styles.module.scss';
import { GetEarnedWageAccessApi } from 'api/endpoints/pinWheel';

interface EwaTableProps {
  data: GetEarnedWageAccessApi;
  connectedAccountName: string;
}

export const EwaTable = ({ data, connectedAccountName }: EwaTableProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <Typography className={styles.title}>
          $ {helper.moneyFormat(data?.currentStatus.ewaAmount)}
        </Typography>
        <Typography className={styles.subTitle}>
          to {connectedAccountName}
        </Typography>
      </div>
      <Divider />
      <div className={styles.info}>
        <div>
          <Typography className={styles.subTitle}>
            Connected direct deposit account
          </Typography>
          <Typography>{connectedAccountName}</Typography>
        </div>
        <div>
          <Typography className={styles.subTitle}>
            Earned wage access type
          </Typography>
          <Typography>
            {getEwaFrequencyText(data?.currentStatus.frequency)}
          </Typography>
        </div>
        {/* <div>
          <Typography className={styles.subTitle}>Status</Typography>
          <Typography>{ewaData?.connectedFinancialAccountId}</Typography>
        </div> */}
        <div>
          <Typography className={styles.subTitle}>Refund date</Typography>
          <Typography>
            {moment(data?.nextPayDate).format('MM/DD/YYYY')}
          </Typography>
        </div>
        <div>
          <Typography className={styles.subTitle}>Fee amount</Typography>
          <Typography>${helper.moneyFormat(data?.fee)}</Typography>
        </div>
      </div>
    </div>
  );
};
