import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useExternalAccounts } from "./useExternalAccounts";

export const useExternalAccountById = () => {
  const { accountId } = useParams();

  const { financialAccounts, isLoading } = useExternalAccounts();
  
  const account = useMemo(
    () =>
      financialAccounts
        .find((fa) => fa.id === accountId),
      [financialAccounts, accountId]
  );

  return {
    isLoading,
    account: account
  }
}

