import { TransitionAnimation } from "shared/ui/animations";
import { ListButton } from "shared/ui/lists";
import { Button } from "shared/ui/buttons";
import { ChevronRightIcon, UnlinkIcon } from "shared/ui/icons";
import { useUnlinkExternalAccount } from "shared/hooks/useUnlinkExternalAccount";

import styles from './style.module.scss';

export const More = () => {
  const unlinkModal = useUnlinkExternalAccount()

  return (
    <TransitionAnimation>
      <div>
        <ListButton className={styles.listBtn}>
          <Button
            startIcon={<UnlinkIcon />}
            endIcon={<ChevronRightIcon />}
            onClick={unlinkModal}
          >
            Unlink account
          </Button>
        </ListButton>
      </div>
    </TransitionAnimation>
  );
}
