import React, { FC } from "react";
import ForgotForm from "pages/login/components/forgotForm";
import { useTranslationProvider } from "providers/translation/translation.provider";
import InputPasswordGroup from "../inputPasswordGroup";
import { RECOVERY_EMAIL, RECOVERY_SMS } from "../..";
import styles from "./style.module.scss";
import TimerSMS from "../timerSMS";
import { Button } from "@mui/material";
interface RecoveryCodeProps {
  code: string;
  onChangeCode: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  isLoading: boolean;
  onSumbit: () => void;
  password: string;
  confirmPassword: string;
  setPassword: (string) => void;
  setConfirmPassword: (string) => void;
  validErrorPassword: string;
  setValidErrorPassword: (string) => void;
  validErrorCode: string;
  codeMaxLength?: number;
  typeMode: string;
  path: string;
  nextSendDate?: Date | undefined;
  setNextSendDate?: (timer: Date | undefined) => void;
  onResend?: () => void;
}

const RecoveryCode: FC<RecoveryCodeProps> = ({
  code,
  onChangeCode,
  onCancel,
  isLoading,
  onSumbit,
  password,
  confirmPassword,
  setPassword,
  setConfirmPassword,
  validErrorPassword,
  setValidErrorPassword,
  validErrorCode,
  codeMaxLength,
  typeMode,
  path,
  nextSendDate,
  setNextSendDate,
  onResend,
}) => {
  const { t } = useTranslationProvider();

  const subTitlemessageSMS = (
    <p className={styles.subTitle}>
      {t("Login.Enter verification code", {
        defaultValue: "Enter verification code that we sent you via SMS to",
      })}{" "}
      <span className={styles.path}>+1 **** {path}</span>{" "}
      {t("Login.and create a new password.", {
        defaultValue: "and create a new password.",
      })}
    </p>
  );

  const subTitleOTP = (
    <p className={styles.subTitle}>
      {t("Login.Enter verification code that we sent you to an email", {
        defaultValue: "Enter verification code that we sent you to an email",
      })}{" "}
      <span className={styles.path}>{path}</span>{" "}
      {t("Login.and create a new password.", {
        defaultValue: "and create a new password.",
      })}
    </p>
  );
  const subTitle =
    typeMode === RECOVERY_EMAIL ? subTitleOTP : subTitlemessageSMS;

  const renderTimer = () => {
    return (
      <div className={styles.boxTimer}>
        {nextSendDate ? (
          <TimerSMS
            targetDate={nextSendDate!}
            onTimerComplete={setNextSendDate!}
          />
        ) : (
          <Button
            onClick={onResend}
            variant="text"
            sx={{
              color: "#187AC9",
            }}
          >
            re-send code
          </Button>
        )}
      </div>
    );
  };

  return (
    <ForgotForm
      title={t("Login.Enter verification code and create new password", {
        defaultValue: "Enter verification code and create new password",
      })}
      subTitle={subTitle}
      inputLabel={t("Login.Verification code", {
        defaultValue: "Verification code",
      })}
      inputValue={code}
      handleOnChange={onChangeCode}
      isLoading={isLoading}
      sumbitButtonText={t("common.Confirm", {
        defaultValue: "Confirm",
      })}
      onSumbit={onSumbit}
      cancelButtonText={t("common.Back", {
        defaultValue: "Back",
      })}
      onCancel={onCancel}
      inputMaxLength={codeMaxLength}
      disable={!code || !password || !confirmPassword}
      error={validErrorCode}
    >
      <InputPasswordGroup
        password={password}
        confirmPassword={confirmPassword}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        validError={validErrorPassword}
        setValidError={setValidErrorPassword}
      />
      {/*{typeMode === RECOVERY_SMS && renderTimer()}*/}
    </ForgotForm>
  );
};

export default RecoveryCode;
