import { Box } from "@mui/material";
import { useExternalAccounts } from "api/hooksApi/useExternalAccounts";
import AccountItem from "../../../AccountItem";
import { Button } from "shared/ui/buttons";
import { useUnlinkExternalAccount } from "shared/hooks/useUnlinkExternalAccount";
import { UnlinkBlueIcon } from "shared/ui/icons/unlink-blue-icon";

export const List = () => {
  const { financialAccounts } = useExternalAccounts();

  const unlinkModal = useUnlinkExternalAccount()

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'24px'}>
      {financialAccounts.map((fa) => {
          return (
            <Box key={fa.id} display={'flex'} justifyContent={'space-between'} >
              <AccountItem account={fa} />
              <Button
                variant="text"
                endIcon={<UnlinkBlueIcon />}
                onClick={unlinkModal}
              >
                Unlink
              </Button>
            </Box>
          )
      })}
    </Box>
  )
}

export default List;