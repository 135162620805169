import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from 'api/baseApi';
import { ApiTags } from '../api-tags';
import { NotificationsGroup, NotificationsLevel } from 'enums';

export interface GetNotificationsApi {
  id: number;
  createdDate: string;
  subject: string;
  body: string;
  group: NotificationsGroup;
  level: NotificationsLevel;
  dateRead: string | null;
}
export interface GetNotificationsArg {
  skip?: number;
  take?: number;
  group?: NotificationsGroup;
}

export interface GetNotificationsNewApi {
  group: NotificationsGroup;
  count: number;
}

interface NotificationsReadArg {
  ids?: number[];
  notificationGroup?: NotificationsGroup;
  readAll: boolean;
}

export enum NotificationTypeEnum {
  deposit = 0,
  withdrawals,
  cardManagement,
}

interface NotificationType {
  emailEnabled: boolean;
  notificationType: NotificationTypeEnum;
  id: number;
}

export const notificationsApi = createApi({
  baseQuery: rtkBaseQuery,
  reducerPath: 'notification',
  tagTypes: [ ApiTags.GetNotificationSettingsTypes],
  endpoints: (builder) => ({
    getNotifications: builder.query<GetNotificationsApi[], GetNotificationsArg>(
      {
        query: ({ skip, take, group }) => ({
          url: 'api/notifications',
          method: 'GET',
          params: {
            skip,
            take,
            group
          },
        }),
      }
    ),
    getNotificationsNew: builder.query<GetNotificationsNewApi[], void>({
      query: () => ({
        url: 'api/notifications/new',
        method: 'GET',
      }),
    }),
    notificationsRead: builder.mutation<void, NotificationsReadArg>({
      query: (queryArg) => ({
        url: 'api/notifications/read',
        method: 'PUT',
        body: queryArg,
      }),
    }),
    notificationsGetSettingTypes: builder.query<NotificationType[], void>({
      providesTags: [ApiTags.GetNotificationSettingsTypes],
      query: () => ({
        url: 'settings/notificationSettingsTypes',
        method: 'GET',
      }),
    }),
    notificationsUpdateSettingTypes: builder.mutation<any, NotificationType[]>({
      invalidatesTags: [ApiTags.GetNotificationSettingsTypes],
      query: (data) => ({
        url: 'settings/notificationSettingsTypes',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useGetNotificationsQuery,
  useGetNotificationsNewQuery,
  useNotificationsReadMutation,
  useNotificationsGetSettingTypesQuery,
  useNotificationsUpdateSettingTypesMutation,
  useLazyGetNotificationsQuery,
} = notificationsApi;
