import { Skeleton } from "components/skeleton";
import { useExternalAccounts } from "api/hooksApi/useExternalAccounts";
import { ExternalAccountList } from "./components/ExternalAccountList";

const ExternalAccounts = () => {
  const { isLoading } = useExternalAccounts();
  
  if (isLoading) {
    return <Skeleton width="100%" height="210px" />
  }

  return <ExternalAccountList />
}

export default ExternalAccounts;