import { AnimateWrapper } from "components/animate-wrapper"
import SimpleContainer from "shared/components/containers/simpleContainer"
import { AccountHeader } from "./AccountHeader"
import { AccountTransactions } from "./AccountTransactions"
import { FilterTransactionsControls } from "./FilterTransactionsControls"
import { Typography } from "shared/ui/typography"
import { useNavigateToExternalAccounts } from "pages/home/hooks"

import styles from './style.module.scss';

const ExternalAccount = () => {
  const { navigateToExternalAccounts } = useNavigateToExternalAccounts();
  
  return (
    <SimpleContainer
      title={'Your Account'}
      onTitleClick={navigateToExternalAccounts}
    >
      <AnimateWrapper className='fade'>
        <div className={styles.container}>
          <AccountHeader />
          <Typography variant='h6'>Transactions</Typography>
          <FilterTransactionsControls />
          <AccountTransactions />
        </div>
      </AnimateWrapper>
    </SimpleContainer>
  )
}

export default ExternalAccount;


