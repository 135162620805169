import { useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useRewardIntegrations } from 'api/hooksApi/useRewardsIntegrations';
import { Place } from 'api/endpoints/rewardIntegrations';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import { SliderRewards } from './componetns/sliderRewards';
import { useRewardIntegrationsHandler } from '../../../../../../providers/rewardIntegrationsHandler';

const DESCTOP_LENGTH = 4;
const MOB_LENGTH = 1.5;

const FeaturedRewards = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslationProvider();
  const rewardHandler = useRewardIntegrationsHandler();

  const { rewardIntegrations, rewardIntegrationsIsLoading } =
    useRewardIntegrations({ place: Place.Reward });

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const settingsSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: matches ? DESCTOP_LENGTH : MOB_LENGTH,
    slidesToScroll: 1,
    arrows: false,
  };

  const BANNER_LENGTH = rewardIntegrations?.length;

  const disabledPrevSlide = currentSlideIndex === 0;

  const disabledNextSlideDesctop =
    currentSlideIndex === BANNER_LENGTH - DESCTOP_LENGTH;

  const disabledNextSlideMob = currentSlideIndex === BANNER_LENGTH - MOB_LENGTH;

  const disabledNextSlide = matches
    ? disabledNextSlideDesctop
    : disabledNextSlideMob;

  return (
    <>
      <SliderRewards
        title={t('Rewards.Featured rewards for you', {
          defaultValue: 'Featured rewards for you',
        })}
        data={rewardIntegrations}
        loadingData={rewardIntegrationsIsLoading}
        disabledPrevSlide={disabledPrevSlide}
        disabledNextSlide={disabledNextSlide}
        setCurrentSlideIndex={setCurrentSlideIndex}
        settingsSlider={settingsSlider}
        sliderHandler={rewardHandler.handle}
        autoplay={false}
        infinite={false}
      />
    </>
  );
};

export default FeaturedRewards;
