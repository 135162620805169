import { FC } from "react";
import styles from "./styles.module.scss";
import convertIcon from "assets/svg/convrt-gray.svg";
import { useTranslationProvider } from "providers/translation/translation.provider";
import cx from "classnames";
import { replaceNumberWithComma } from "shared/utils/price";
import { findRewardCategoryByType } from "pages/reward/lib/findRewardCategoryByType";
interface HistoryItemProps {
  date: string;
  points: number;
  amount: string;
  type: number;
  typeName: string;
  subjectName: string | null;
}

const HistoryItem: FC<HistoryItemProps> = ({
  points,
  date,
  amount,
  typeName,
  type,
  subjectName,
}) => {
  const { t } = useTranslationProvider();

  const item = findRewardCategoryByType(type);


  const isPositive = points > 0;
  const pointsColor = isPositive
    ? cx(styles.boxPoints_points, styles.positive)
    : styles.boxPoints_points;

  const title = t(item?.title!, {
    defaultValue: item?.defaultTitle!,
  }) || typeName

  return (
    <div className={styles.container}>
      <div className={styles.boxLabel}>
        <img src={item?.img} />
        <div className={styles.box}>
          <span>
            {`${title || ''} ${subjectName || ''}`}
          </span>
          <span>{date}</span>
        </div>
      </div>

      <div className={styles.boxPoints}>
        <span className={pointsColor}>
          {isPositive && "+"}
          {replaceNumberWithComma(points)} points
        </span>
        <div>
          <img src={convertIcon} alt="Convert" />
          <span>${amount}</span>
        </div>
      </div>
    </div>
  );
};

export default HistoryItem;
