import { PayeeHistoryAllApi } from 'api/endpoints/financialAccounts'
import { Typography } from 'shared/ui/typography'
import { InfoIcon, CloseOutlineGrayIcon } from 'shared/ui/icons'
import styles from './styles.module.scss'
import moment from 'moment'
import { ExternalBadgeStatus } from '../externalBadgeStatus'
import { ExternalBadgeProviderStatus } from '../externalBadgeProviderStatus'
import { AccountStatus } from 'api/endpoints/fundsMovement'
import TooltipChildren from 'shared/components/toolTip/toolTipChildren'
import { TransitionAnimation } from 'shared/ui/animations'

interface ConnectedPayeeHistoryProps {
  payee: PayeeHistoryAllApi
}

export const ConnectedPayeeHistory = (props: ConnectedPayeeHistoryProps) => {
  const { payee } = props

  const renderTooltip = () => {
    const isTooltipVisible = [
      AccountStatus.REJECTED,
      AccountStatus.CLOSED,
      AccountStatus.REVIEW,
    ].includes(payee.status)

    if (isTooltipVisible) {
      return (
        <TooltipChildren description={payee.verificationError || ''}>
          <div>
            <InfoIcon />
          </div>
        </TooltipChildren>
      )
    }
  }

  return (
    <TransitionAnimation>
      <div className={styles.container}>
        <div className={styles.containerInfo}>
          <div className={styles.boxText}>
            <Typography>{payee.accountNickname}</Typography>
            <Typography>{`**** ${payee.accountNumberDisplay}`}</Typography>
            <Typography>
              {`Date of request: ${moment(payee.createdDate).format(
                'MM/DD/YYYY'
              )}`}
            </Typography>
          </div>
          <div className={styles.boxBadge}>
            <ExternalBadgeProviderStatus provider={payee.provider} />
            <ExternalBadgeStatus status={payee.status} />
            {renderTooltip()}
          </div>
        </div>
      </div>
    </TransitionAnimation>
  )
}
