import { AnimateWrapper } from 'components/animate-wrapper';
import { Typography } from 'shared/ui/typography';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import styles from './style.module.scss';

export const EmptySubUserList = () => {
  return (
    <AnimateWrapper className='fade'>
      <div className={styles.wrapper}>
        <PersonAddOutlinedIcon className={styles.icon} />
        <Typography>No authorized users</Typography>
        <Typography className={styles.subTitle}>
          Authorized users are individuals you assign to specific cards or
          accounts. They can be given access to these financial tools to perform
          actions like making payments or viewing details. You decide who gets
          access and what permissions they have.
        </Typography>
      </div>
    </AnimateWrapper>
  );
};
