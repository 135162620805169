import { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { EwaForm } from 'pages/settings/earnedWageAccess/componentns/ewaForm';
import { useBoolean } from 'shared/hooks/useBoolean';
import { UseBooleanReturn } from 'shared/hooks/useBoolean';
import { useGetEarnedWageAccessQuery } from 'api/endpoints/pinWheel';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { GetEarnedWageAccessApi } from 'api/endpoints/pinWheel';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { ConnectDirectDipositModal } from 'pages/settings/earnedWageAccess/componentns/connectDirectDepositModal';
import { useSwitchDirectDepositDrawer } from 'providers/switchDirectDeposit';
import { EwaEditAccessModal } from 'pages/settings/earnedWageAccess/componentns/ewaEditAccessModal';
import { useSearchParams } from 'react-router-dom';
import {
  EWA,
  DIRECT_DEPOSIT,
} from 'pages/settings/accountSettings/searchParamsTypes';
import { ewaDirDespositError } from 'pages/settings/accountSettings/config/constants';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { useSnackBar } from 'providers/snackBar.provider';
import { useEffect } from 'react';
import DrawerModal from 'shared/components/drawerModal';
import helper from 'services/helper';
interface EwaValue {
  value: UseBooleanReturn['value'];
  show: UseBooleanReturn['setTrue'];
  hide: UseBooleanReturn['setFalse'];
  data?: GetEarnedWageAccessApi;
  isLoading: boolean;
  connectedAccount: FinancialAccount | undefined;
  connectedAccountName: string;
  isBorrower: boolean;
}

const initialValue: EwaValue = {
  value: false,
  show: () => {},
  hide: () => {},
  data: undefined,
  isLoading: false,
  connectedAccount: undefined,
  connectedAccountName: '',
  isBorrower: false,
};

const EwaContext = createContext<EwaValue>(initialValue);

export const EwaProvider = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const snackBar = useSnackBar();
  const page = searchParams.get('page');
  const ewa = useBoolean();
  const dirDepositContext = useSwitchDirectDepositDrawer();

  const {
    data,
    isFetching: isLoadingEwa,
    isError,
    error,
  } = useGetEarnedWageAccessQuery();

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const connectedAccount = unclosedFinancialAccounts?.find(
    (acc) => acc.financialAccountId === data?.connectedFinancialAccountId
  );

  const connectedAccountName = connectedAccount
    ? `${connectedAccount?.name} (${connectedAccount?.accountNumber.slice(-4)})`
    : '';

  const titleForm = data?.currentStatus?.isActive
    ? 'Edit Earned Wage Access'
    : 'Earned Wage Access';

  const isLoading = isLoadingEwa || financialAccountsIsLoading;

  const isDrawer = !!data && !data?.currentStatus.userOwnsMoney;

  const isBorrower =
    !!data?.currentStatus?.isActive && !!data?.currentStatus?.userOwnsMoney;

  const isNotHaveDirDeposit =
    isError && (error as FetchBaseQueryError)?.data === ewaDirDespositError;

  const navigateDirDeposit = () => {
    page === EWA
      ? setSearchParams({
          page: DIRECT_DEPOSIT,
        })
      : dirDepositContext.show();
  };

  const value = useMemo(() => {
    return {
      value: ewa.value,
      show: ewa.setTrue,
      hide: ewa.setFalse,
      data,
      isLoading,
      connectedAccount,
      connectedAccountName,
      isBorrower,
    };
  }, [ewa.value, data, isLoading]);

  useEffect(() => {
    if (ewa.value && isError && !isNotHaveDirDeposit) {
      const ewaErrors =
        error &&
        'data' in error &&
        helper.formatErrors((error as FetchBaseQueryError).data);

      snackBar.setSnackBar({
        type: 'error',
        isShow: true,
        message: ewaErrors,
      });
      ewa.setFalse();
    }
  }, [ewa.value, error]);

  return (
    <EwaContext.Provider value={value}>
      {children}

      <ConnectDirectDipositModal
        isShow={value.value && isNotHaveDirDeposit}
        onClose={value.hide}
        handleNavigate={navigateDirDeposit}
      />
      <EwaEditAccessModal
        isShow={value.value && isBorrower}
        onClose={value.hide}
      />
      <DrawerModal
        isShow={value.value && isDrawer}
        onClose={value.hide}
        titleText={titleForm}
      >
        <EwaForm />
      </DrawerModal>
    </EwaContext.Provider>
  );
};

export const useEwa = () => useContext(EwaContext);
