import {
    createTheme,
    responsiveFontSizes,
    ThemeOptions,
} from '@mui/material/styles';
import { CustomColors } from './ThemeInterface';

import { Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
    interface ThemeOptions {
        colors: CustomColors;
    }

    interface Theme extends ThemeOptions {
        colors: CustomColors;
    }
}
declare module 'styled-components' {
    export interface DefaultTheme extends Theme {}
}

export const colors: CustomColors = {
    sidebarBg: '#023047',
    sidebarSelect: '#1b4559',
    sidebarHover: '#011d2b',
    neteviaBlue: '#56c3f2',
    deepBlue: '#51b9e5',
    darkBlue: '#023047',
    neteviaWeb: '#50c5e2',
    darkGrey: '#3b3e43',
    primaryBlue: '#187AC9',
    darkGreySecond: '#939598',
    borderLightGrey: ' #0000001f',
    bgLightGrey: '#f8f9fb',
    lightStrokeColor: '#ededed',
    red: '#ff8585',
    white: '#FFFFFF',
    bgGrey: '#F6FAFD',
    black: 'black',
    warning: '#EF6C00',
    textSecondary: '#00000099',
    borderLightGrey2: '#e5e5e5',
    errorMain: '#d32f2f',
    green: '#65BE73',
    lightGrey: '#CFD8DC',
};

export const theme: Theme = responsiveFontSizes(
    createTheme({
        colors,
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1440,
                xl: 1536,
            },
        },
        palette: {
            mode: 'light',
            primary: {
                main: '#008bf5',
            },
            secondary: {
                main: '#008bf5',
            },
            background: {
                default: '#ffffff',
            },
            text: {
                secondary: colors.textSecondary,
            },
            error: {
                main: colors.errorMain,
            }
        },
        typography: {
            fontFamily: 'Noto Sans, DM Sans, sans-serif',
        },

        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        '& .MuiOutlinedInput-input': {
                            borderColor: theme.colors.borderLightGrey2,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.colors.borderLightGrey2,
                        },
                        '& .MuiInputLabel-root': {
                            color: 'green',
                        },
                        '&:hover .MuiOutlinedInput-input': {},
                        '&:hover .MuiInputLabel-root': {},
                        '&:hover .MuiOutlinedInput-notchedOutline': {},
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {},
                        '& .MuiInputLabel-root.Mui-focused': {},
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {},
                    }),
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
        },
    }),
);
