import { EwaFrequencyTiltle } from '../types';

export const getEwaFrequencyText = (frequency: number) => {
  switch (frequency) {
    case 1:
      return EwaFrequencyTiltle.ForThisTimeOnly;
    case 2:
      return EwaFrequencyTiltle.ForEveryFuturePaycheck;
    default:
      return '';
  }
};
