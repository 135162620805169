import CreditCardOutlined from 'assets/svg/CreditCardOutlined.svg';
import CasesOutlined from 'assets/svg/CasesOutlined.svg';
import USPS from 'assets/svg/USPS svg.svg';
import UPS from 'assets/svg/ups-logo 1.svg';
import styles from './style.module.scss';
import { Address, Person } from '..';
import { useEffect, useState } from 'react';
import { useDeliveryCompanyControllerMutation } from 'api/endpoints/enums';
import { Skeleton } from '@mui/material';
import { FinancialAccount } from 'api/endpoints/financialAccounts';
import { Button } from 'shared/ui/buttons';

interface PhysicalCardSummaryProps {
  account: FinancialAccount;
  person: Person;
  holder: any;
  adress?: Address;
  deliveryChoise: string;
  isLoading: boolean;
  onSubmit: () => void;
}

export const PhysicalCardSummary = ({
  account,
  adress,
  deliveryChoise,
  onSubmit,
  isLoading,
}: PhysicalCardSummaryProps) => {
  const [deliveryCompanyMutation, { isLoading: deliveryCompanyIsLoading }] =
    useDeliveryCompanyControllerMutation();
  const [delivery, setDelivery] = useState<any>([]);

  useEffect(() => {
    getDeliveryCompany();
  }, []);

  const getDeliveryCompany = async () => {
    const res = await deliveryCompanyMutation({}).unwrap();
    const unique = new Map(res?.map((el) => [el.name, el]));
    const item = Object.fromEntries(unique);
    setDelivery(item);
  };
  const deliveryItem = delivery[deliveryChoise];

  const handleSubmit = () => {
    onSubmit();
  };
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>Confirm entered details</div>
        <div className={styles.content}>
          <div className={styles.detailsItem}>
            <img src={CreditCardOutlined} />
            <div className={styles.detailsItem_content}>
              <div className={styles.detailsItem_title}>Type of card</div>
              <div className={styles.detailsItem_value}>Physical</div>
            </div>
          </div>
          <div className={styles.detailsItem}>
            <img src={CasesOutlined} />
            <div className={styles.detailsItem_content}>
              <div className={styles.detailsItem_title}>Selected account</div>
              <div className={styles.detailsItem_value}>{`${
                account.name
              } (${account?.accountNumber?.slice(-4)})`}</div>
            </div>
          </div>
        </div>
      </div>
      {deliveryCompanyIsLoading ? (
        <Skeleton height={200} />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.title}>Delivery service</div>
          <div className={styles.content}>
            <div className={styles.deliveryInfo}>
              <div className={styles.deliveryInfo_row}>
                <div className={styles.deliveryInfo_label}>
                  <img src={deliveryItem?.groupName === 'UPS' ? UPS : USPS} />
                  {deliveryItem?.groupName}
                </div>
              </div>
              <div className={styles.deliveryInfo_row}>
                <div className={styles.deliveryInfo_label}>Shipping type</div>
                <div
                  className={styles.deliveryInfo_value}
                >{`${deliveryItem?.groupName} (${deliveryItem?.description})`}</div>
              </div>
              <div className={styles.deliveryInfo_row}>
                <div className={styles.deliveryInfo_label}>Cost</div>
                <div className={styles.deliveryInfo_value}>FREE</div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.title}>Shipping address</div>
        <div className={styles.content}>
          <div className={styles.deliveryInfo}>
            {`${adress?.streetAddress},${
              adress?.extendedAddress ? ` ${adress?.extendedAddress},` : ''
            } ${adress?.locality}, ${adress?.region} ${adress?.postalCode}`}
          </div>
        </div>
      </div>
      <div>
        <Button
          onClick={handleSubmit}
          loading={isLoading}
          variant='contained'
        >
          <div>PLACE ORDER</div>
        </Button>
      </div>
    </div>
  );
};
