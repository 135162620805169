import { FC } from "react";
import XXYY from "assets/svg/YY.svg";
import IconCopy from "assets/svg/copy-white.svg";
import helper from "services/helper";
import CardLines from "./component/cardLines";
import CardCVV from "./component/cardCVV";
import styles from "./style.module.scss";
import cx from 'classnames';
import moment from 'moment/moment';

interface CardNumberLineComponentProps {
  last4?: string;
  isShow: boolean;
  copy: (cardNumber: string) => void;
  cardNumber: string;
  cardCvv: string;
  cardExpirationDate: string;
  isPrimaryColorMode?: boolean;
}

const CardNumberLineComponent: FC<CardNumberLineComponentProps> = ({
  cardNumber,
  cardCvv,
  cardExpirationDate,
  last4,
  isShow,
  copy,
  isPrimaryColorMode,
}) => {
  return (
    <div className={cx(styles.container, isPrimaryColorMode && styles.containerBlackMode)}>
      <div className={styles.containerCardNumber}>
        {isShow ? (
          <div
            className={styles.containerCardNumber_show}
            onClick={(e) => {
              e.stopPropagation();
              copy(cardNumber);
            }}
          >
            {helper.formatCardNumber(cardNumber)}
            <img className={styles.containerCVV_icon} src={IconCopy} />
          </div>
        ) : (
          <div className={styles.containerCardNumber_hide}>
            <CardLines />
            <CardLines />
            <CardLines />
            <div className={styles.containerCardNumber_last4}>
              <p>{last4}</p>
            </div>
          </div>
        )}
      </div>
      <div className={styles.containerCVV}>
        {isShow ? (
          <>
            <div
              className={styles.containerCVV_show}
              onClick={(e) => {
                e.stopPropagation();
                copy(moment.utc(cardExpirationDate).format('MM/YY'));
              }}
            >
              {moment.utc(cardExpirationDate).format('MM/YY')}
              <img className={styles.containerCVV_icon} src={IconCopy} />
            </div>
            <div
              className={styles.containerCVV_show}
              onClick={(e) => {
                e.stopPropagation();
                copy(cardCvv);
              }}
            >
              {cardCvv}
              <img className={styles.containerCVV_icon} src={IconCopy} />
            </div>
          </>
        ) : (
          <div className={styles.containerCVV_hide}>
            <img className={styles.containerCVV_imgXXYY} src={XXYY} />
            <CardCVV />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardNumberLineComponent;
