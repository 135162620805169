import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  Divider,
} from '@mui/material';
import { Skeleton } from 'components/skeleton';
import { CardView } from 'shared/components/CardView';
import { Typography } from 'shared/ui/typography';
import { Badge } from 'shared/ui/badge';
import { Button } from 'shared/ui/buttons';
import { PaymentCard } from 'api/endpoints/paymentCards';
import { TransitionAnimation } from 'shared/ui/animations';
import { formatAccountName } from 'shared/lib/format';
import styles from './styles.module.scss';
import cn from 'classnames';
import { useHomePage } from '../provider';
import { EmptyTable } from '../emptyTable';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from 'routes';
import { useScreenSize } from 'shared/hooks/useScreensSize';

const cardsTableHeader = ['Card', 'Account', 'Cardholder name'];

export const CardsTable = () => {
  const { isMobile } = useScreenSize();
  const navigate = useNavigate();
  const {
    homeCards,
    financialAccounts,
    financialAccountsIsLoading,
    cardsIsLoading,
  } = useHomePage();
  const firstThreeCards = !!homeCards.length ? homeCards.slice(0, 3) : [];

  const handleNavigateToCard = (accountId: string | null, cardId: string) => {
    if (accountId) {
      navigate(appRoutes.cardById(accountId, cardId));
    }
  };

  const renderCardHolderRow = (card: PaymentCard) =>
    card.subProfile ? (
      <div className={cn(styles.boxMob, styles.box)}>
        <Typography className={cn(styles.boxMobName, styles.boxName)}>
          {card.subProfile}
        </Typography>
        <Badge label='Authorized user' styledLabel={styles.styledLabelBadge}/>
      </div>
    ) : (
      <Typography className={cn(styles.boxMobName, styles.boxName)}>
        {card.cardholder}
      </Typography>
    );

  const renderFinAccRow = (card: PaymentCard) => {
    const financialAccount = !!card?.financialAccounts?.length
      ? financialAccounts.find(
          (account) =>
            account.financialAccountId === card?.financialAccounts[0]?.id
        )
      : null;

    if (!financialAccount) return;

    return (
      <div className={styles.box}>
        <Typography className={cn(styles.ellipsisText, styles.boxName)}>
          {isMobile
            ? formatAccountName(financialAccount?.name, 9)
            : financialAccount?.name}
        </Typography>
        <Typography className={cn(styles.boxMobLast4, styles.boxLast4)}>
          ({financialAccount?.accountNumber.slice(-4)})
        </Typography>
      </div>
    );
  };

  const renderCardRow = (card: PaymentCard) => (
    <>
      <CardView
        isSmallSize
        card={card}
      />
      <div className={cn(styles.boxMob, styles.box)}>
        <Typography className={styles.boxName}>
          {card.cardName !== null ? card.cardName : 'Business Debit'}
        </Typography>
        <Typography className={styles.boxLast4}>
          {card.last4 ? `(****${card.last4})` : '(****XXXX)'}
        </Typography>
        {isMobile && renderCardHolderRow(card)}
      </div>
    </>
  );

  return cardsIsLoading || financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height={isMobile ? '600px' : '300px'}
    />
  ) : (
    <TransitionAnimation>
      {!!homeCards?.length ? (
        <Table
          classes={{ root: styles.table }}
          component='div'
        >
          {!isMobile && (
            <TableHead
              component='div'
              classes={{ root: styles.header }}
            >
              <TableRow
                component='div'
                classes={{ root: styles.headerRow }}
              >
                {cardsTableHeader.map((label) => (
                  <TableCell
                    key={label}
                    scope='row'
                    component='div'
                  >
                    {label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody
            classes={{ root: styles.tableBody }}
            component='div'
          >
            {firstThreeCards?.map((card, index) => (
              <React.Fragment key={card.id}>
                <TableRow
                  key={card.id}
                  component='div'
                  classes={{ root: styles.tableBodyRow }}
                  onClick={() =>
                    handleNavigateToCard(card.financialAccounts[0]?.id, card.id)
                  }
                >
                  <TableCell
                    component='div'
                    scope='row'
                  >
                    {renderCardRow(card)}
                  </TableCell>
                  <TableCell
                    component='div'
                    scope='row'
                  >
                    {renderFinAccRow(card)}
                  </TableCell>

                  {!isMobile && (
                    <TableCell
                      component='div'
                      scope='row'
                    >
                      {renderCardHolderRow(card)}
                    </TableCell>
                  )}
                </TableRow>
                {isMobile && index !== firstThreeCards.length - 1 && (
                  <Divider />
                )}
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter
            classes={{ root: styles.tableFooter }}
            component='div'
          >
            {isMobile && <Divider />}

            <Button
              variant='text'
              onClick={() => navigate(appRoutes.homeAllCards())}
            >
              View all
            </Button>
          </TableFooter>
        </Table>
      ) : (
        <EmptyTable
          icon={<CreditCardOutlinedIcon className={styles.emptyIcon} />}
          title='You don’t have any cards'
          subTitle='Your cards will be displayed here once added.'
        />
      )}
    </TransitionAnimation>
  );
};
