import { Tabs, Tab } from '@mui/material';
import { ModeHomeLists } from '../types';
import { Button } from 'shared/ui/buttons';
import { useHomePage } from '../provider';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';
import { useScreenSize } from 'shared/hooks/useScreensSize';
import { usePermission } from 'shared/hooks/usePermission';

export const HomeTableTopPanel = () => {
  const { isMobile } = useScreenSize();
  const [_, setSearchParams] = useSearchParams();
  const { modeHomeList, changeModeHomeList } = useHomePage();
  const { canOpenAccount, canOpenCard } = usePermission();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: ModeHomeLists
  ) => {
    changeModeHomeList(newValue);
  };
  const itsAccount = modeHomeList === ModeHomeLists.Accounts;
  const modes = [ModeHomeLists.Accounts, ModeHomeLists.Cards];

  const addNew = () => {
    itsAccount
      ? setSearchParams({ drawerMode: 'addAccount' })
      : setSearchParams({ mode: 'openCard' });
  };

  const renderButton = () => {
    if (canOpenAccount || canOpenCard) {
      const label = itsAccount ? 'Account' : 'Card';
      return (
        <Button
          variant='text'
          onClick={addNew}
        >
          + Add {label}
        </Button>
      );
    }
    return <></>;
  };

  return (
    <div className={styles.container}>
      <Tabs
        value={modeHomeList}
        onChange={handleChange}
        classes={{
          indicator: styles.tabIndicator,
          root: styles.tabsRoot,
        }}
      >
        {modes.map((mode) => (
          <Tab
            key={mode}
            label={mode}
            value={mode}
            classes={{ selected: styles.tabSelected, root: styles.tabRoot }}
          />
        ))}
      </Tabs>
      {!isMobile && renderButton()}
    </div>
  );
};
