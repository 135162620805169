import { Typography } from 'shared/ui/typography'
import { CloseOutlineGrayIcon } from 'shared/ui/icons'
import { IconButton } from '@mui/material'
import { PlaidPayees } from 'api/hooksApi/useExternalPayees'
import { ExternalBadgeProviderStatus } from '../externalBadgeProviderStatus'
import { ExternalBadgeStatus } from '../externalBadgeStatus'
import { CircularLoader } from 'shared/ui/loaders'

import styles from './styles.module.scss'

interface ConnectedPayeeProps {
  payee: PlaidPayees
  handleDeletePayee: (id: string) => Promise<void>
  isLoadingDelete: boolean
  deletingPayeeId: string | null
}

export const ConnectedPayee = (props: ConnectedPayeeProps) => {
  const { payee, handleDeletePayee, isLoadingDelete, deletingPayeeId } = props

  return (
    <div className={styles.container}>
      <div>
        <Typography>{payee.name}</Typography>
        <Typography>{`**** ${payee.last4}`}</Typography>
        <div>
          <ExternalBadgeProviderStatus provider={payee.provider} />
          <ExternalBadgeStatus status={payee.accountStatus} />
        </div>
      </div>
      <div>
        <IconButton
          onClick={() => handleDeletePayee(payee.id)}
          disabled={isLoadingDelete}
        >
          {isLoadingDelete && deletingPayeeId === payee.id ? (
            <CircularLoader
              color='inherit'
              size={20}
            />
          ) : (
            <CloseOutlineGrayIcon />
          )}
        </IconButton>
      </div>
    </div>
  )
}
