import { useState, useEffect, useRef } from 'react';
import {
  useGetNotificationsNewQuery,
  useNotificationsReadMutation,
  GetNotificationsApi,
  useLazyGetNotificationsQuery,
} from 'api/endpoints/notifications';
import { Skeleton } from 'components/skeleton';
import { NotificationsGroup } from 'enums';
import {
  BellIcon,
  MessageOutlinedIcon,
  UpcomingOutlinedIcon,
} from 'shared/ui/icons';
import { EmptyItem } from './components/emptyItem';
import { ContentHeader } from './components/contentHeader';
import { ContentItem } from './components/contentItem';
import { useSnackBar } from 'providers/snackBar.provider';
import helper from 'services/helper';
import styles from './style.module.scss';
import { useBoolean } from 'shared/hooks/useBoolean';

interface ContentProps {
  contentGroup: NotificationsGroup;
}

export const Content = ({ contentGroup }: ContentProps) => {
  const { setSnackBar } = useSnackBar();

  const [
    getNotificationsTriger,
    { isLoading: isLoadingNot, isFetching: isFetchingNot },
  ] = useLazyGetNotificationsQuery();

  const { data: newNotifications, isLoading: isLoadingNewNotifications } =
    useGetNotificationsNewQuery();

  const [notificationsRead, { isLoading: isLoadingNotificationsRead }] =
    useNotificationsReadMutation();

  const [limit, setlimit] = useState(10);
  const [notification, setNotofication] = useState<GetNotificationsApi[]>([]);

  const isFetchingNotifications = useBoolean(true);
  const isLastNotification = useBoolean();

  const isLoadingNotifications =
    isLoadingNot || (isFetchingNot && limit === 10); 

  const listRef = useRef<HTMLDivElement | null>(null);

  const newContentObj = newNotifications?.find(
    (item) => item.group === contentGroup
  );

  const getNotifications = async () => {
    try {
      const res = await getNotificationsTriger({
        skip: 0,
        take: limit,
        group: contentGroup
      }).unwrap();

      if (res?.length < limit) {
        isLastNotification.setTrue();
      } else {
        setlimit(limit + 10);
      }

      setNotofication(res);
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    } finally {
      isFetchingNotifications.setFalse();
    }
  };

  useEffect(() => {
    if (isFetchingNotifications.value && !isLastNotification.value) {
      getNotifications();
    }
  }, [isFetchingNotifications.value, isLastNotification.value]);

  const onScroll = () => {
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 5) {
        isFetchingNotifications.setTrue();
      }
    }
  };

  const renderEmptyItem = (contentGroup: NotificationsGroup) => {
    const emptyItemProps = {
      [NotificationsGroup.Notifications]: {
        img: <BellIcon />,
        title: 'You don’t have any notifications yet',
        subTitle: 'All notifications will be displayed here.',
      },
      [NotificationsGroup.Messages]: {
        img: <MessageOutlinedIcon />,
        title: 'You don’t have any messages yet',
        subTitle: 'All messages will be displayed here.',
      },
      [NotificationsGroup.Campaigns]: {
        img: <UpcomingOutlinedIcon />,
        title: 'You don’t have any offers yet',
        subTitle: 'All offers will be displayed here.',
      },
    };

    const emptyItemPropsForGroup = emptyItemProps[contentGroup];

    return (
      <EmptyItem
        img={emptyItemPropsForGroup.img}
        title={emptyItemPropsForGroup.title}
        subTitle={emptyItemPropsForGroup.subTitle}
      />
    );
  };

  const readMessage = async (id: number) => {
    try {
      await notificationsRead({
        ids: [id],
        readAll: false,
      }).unwrap();
      getNotifications();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const readAllMessages = async () => {
    try {
      await notificationsRead({
        notificationGroup: contentGroup,
        readAll: true,
      }).unwrap();
      getNotifications();
    } catch (e: any) {
      setSnackBar({
        type: 'error',
        message: helper.formatErrors(e.data),
        isShow: true,
      });
    }
  };

  const renderContentItem = (contentItems: GetNotificationsApi[]) => (
    <div
      className={styles.list}
      ref={listRef}
      onScroll={onScroll}
    >
      {contentItems?.map((item) => (
        <ContentItem
          key={item.id}
          item={item}
          handleReadMessage={readMessage}
          disabled={isLoadingNotificationsRead || Boolean(item.dateRead)}
        />
      ))}
    </div>
  );

  const renderContentList = () => {
    const contentItems = notification?.filter(
      (item) => item.group === contentGroup
    );
    return !!contentItems?.length
      ? renderContentItem(contentItems)
      : renderEmptyItem(contentGroup);
  };

  return isLoadingNewNotifications || isLoadingNotifications ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <div className={styles.container}>
      <ContentHeader
        count={newContentObj?.count || 0}
        disabled={!newContentObj?.count || isLoadingNotificationsRead}
        handleReadAllMessages={readAllMessages}
      />
      {renderContentList()}
    </div>
  );
};
