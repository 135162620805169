import { TransitionAnimation } from 'shared/ui/animations';
import { Button } from 'shared/ui/buttons';
import { Typography } from 'shared/ui/typography';
import helper from 'services/helper';
import { formatAccountName } from 'shared/lib/format';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Select, TextInput } from 'shared/ui/inputs';
import { useFormik } from 'formik';
import styles from './styles.module.scss';
import { replaceNumberWithComma } from 'shared/utils/price';
import * as yup from 'yup';
import { Skeleton } from 'components/skeleton';
import { Option } from 'api/endpoints/loans';
import isEqual from 'lodash/isEqual';
import { Divider } from '@mui/material';
import {
  useLoanSettingsQuery,
  useLazyLoanRequestQuery,
} from 'api/endpoints/loans';
import { useDrawerBehavior } from 'providers/drawer-behavior';
import { useSnackBar } from 'providers/snackBar.provider';

const validationSchema = yup.object().shape({
  loanAmount: yup
    .string()
    .required('The funding amount required')
    .test(
      'is-minimum-amount',
      'The funding amount cannot be less than $500',
      (value) => {
        if (!value) return false;
        const numericValue = parseFloat(value.replace(/[$\s,]/g, ''));
        return numericValue >= 500;
      }
    )
    .test(
      'is-maximum-amount',
      'The funding amount cannot exceed $1,000,000',
      (value) => {
        if (!value) return false;
        const numericValue = parseFloat(value.replace(/[$\s,]/g, ''));
        return numericValue <= 1000000;
      }
    ),
  selectAccount: yup.string().required('Please select an account'),
});

const formatCurrency = (value: string) => {
  const numberValue = value.replace(/[^0-9.]/g, '');
  return numberValue ? `$ ${replaceNumberWithComma(numberValue)}` : '';
};

interface FormFundingApplicationProps {
  options: Option[];
  selectedOption: Option | null;
  signUrl?: string;
}

export const FormFundingApplication = (props: FormFundingApplicationProps) => {
  const { options, selectedOption, signUrl } = props;

  const drawerBehavior = useDrawerBehavior();
  const { setSnackBar } = useSnackBar();

  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();

  const { refetch: refetchLoanSettings } = useLoanSettingsQuery();
  const [loanRequest, { isLoading: isLoadingGetLoanRequest }] =
    useLazyLoanRequestQuery();

  const getLoanRequest = async (amount: string, id: string) => {
    try {
      drawerBehavior.lockForClose();
      const res = await loanRequest({
        loanAmount: amount,
        financialAccountId: id,
      }).unwrap();

      if (res) {
        window.open(res, '_blank');
      }
      refetchLoanSettings();
      drawerBehavior.unlockForClose();
      close();
    } catch (e: any) {
      drawerBehavior.unlockForClose();
      const errorMessage =
        e.status === 500
          ? 'Something went wrong! Please try again later or contact our support'
          : helper.formatErrors(e.data);

      setSnackBar({
        type: 'error',
        message: errorMessage,
        isShow: true,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      loanAmount: selectedOption?.amount.toString() || '',
      selectAccount: '',
    },
    validationSchema,
    onSubmit: (form) => {
      const formattedLoanAmount = form.loanAmount.replace(/[$\s,]/g, '');
      signUrl
        ? window.open(signUrl, '_blank')
        : getLoanRequest(formattedLoanAmount, form.selectAccount);
    },
  });

  const optionsFromAcc = unclosedFinancialAccounts.map((account) => ({
    id: account.financialAccountId,
    value: account.financialAccountId,
    content: (
      <div className={styles.dropDown}>
        <div>
          {formatAccountName(account.name)} (****
          {account.accountNumber.slice(-4)})
        </div>
        <div>$ {helper.moneyFormat(account?.availableCash?.value) || ''}</div>
      </div>
    ),
  }));

  const filteredOptions = options.filter(
    (option) => !isEqual(option, selectedOption)
  );

  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <Typography className={styles.text}>
          Complete your loan application now. Don’t miss out on this special
          offer.
        </Typography>
        <div className={styles.fundingSummary}>
          <div className={styles.fundingSummaryBox}>
            <Typography className={styles.fundingSummaryBoxSelect}>
              You’ve selected this funding offer:
            </Typography>
            <Typography className={styles.fundingSummaryBoxInfo}>
              ${replaceNumberWithComma(selectedOption?.amount)}
            </Typography>
          </div>
          {!!filteredOptions.length && (
            <>
              <Divider />
              <div className={styles.fundingSummaryBox}>
                <Typography className={styles.fundingSummaryBoxOther}>
                  Other available offers:
                </Typography>
                {filteredOptions.map((item, index) => (
                  <Typography
                    key={index}
                    className={styles.fundingSummaryBoxInfo}
                  >
                    ${replaceNumberWithComma(item.amount)}
                  </Typography>
                ))}
              </div>
            </>
          )}
        </div>
        <TextInput
          label='Funding amount'
          name='loanAmount'
          value={formatCurrency(formik.values.loanAmount)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.loanAmount && formik.errors.loanAmount}
          autoComplete='off'
        />
        <Typography className={styles.text}>
          Select the financial account where you would like the funds to be
          deposited:
        </Typography>
        <Select
          fullWidth
          label='Select account'
          options={optionsFromAcc}
          {...formik.getFieldProps('selectAccount')}
        />
        <div className={styles.whatHappens}>
          <Typography>What happens next:</Typography>
          <Typography className={styles.whatHappensSubTitle}>
            After you click “Next”, you will be redirected to the funding
            provider&apos;s webpage to review your information.
            <br />
            Don’t worry - you will have the opportunity to make any changes
            before submitting your application.
          </Typography>
        </div>
        <Button
          type='submit'
          className={styles.btn}
          loading={isLoadingGetLoanRequest}
          disabled={
            !formik.isValid ||
            !formik.values.loanAmount ||
            !formik.values.selectAccount
          }
        >
          Next
        </Button>
      </form>
    </TransitionAnimation>
  );
};
