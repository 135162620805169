import { TransitionAnimation } from 'shared/ui/animations';
import { useLoanSettingsQuery } from 'api/endpoints/loans';
import { OnboardingState } from './components/onboardingSate';
import { LoansInfo } from './components/loansInfo';
import { EmptyLoans } from './components/emptyLoans';
import { GotLoanOffer } from './components/gotLoanOffer';
import { LoanStatusEnum, OnboardingStateEnum } from './types';
import { Typography } from 'shared/ui/typography';
import { Skeleton } from 'components/skeleton';
import styles from './style.module.scss';
import moment from 'moment';

export const Loans = () => {
  const { data, isFetching } = useLoanSettingsQuery();

  const renderContent = () => {
    const isExportedBeforeLeadCreated = moment(
      data?.exportedLoanServie
    ).isBefore(moment(data?.leadCreatedDate));

    if (
      !!data?.loans?.length &&
      data?.loans[0].status !== LoanStatusEnum.RequestedFunding
    ) {
      return (
        <LoansInfo
          loans={data.loans[0]}
          signUrl={data.signUrl}
        />
      );
    }
    if (
      data?.leadDetails &&
      data?.leadDetails?.onboardingState ===
        OnboardingStateEnum.stage1Incomplete
    ) {
      if (isExportedBeforeLeadCreated) {
        return (
          <OnboardingState
            onboardingState={data.leadDetails.onboardingState}
            signUrl={data.signUrl}
          />
        );
      }
      return <GotLoanOffer options={data.options || []} signUrl={data.signUrl}/>;
    }
    if (data?.leadDetails && data?.leadDetails?.onboardingState !== null) {
      return (
        <OnboardingState
          onboardingState={data.leadDetails.onboardingState}
          signUrl={data.signUrl}
        />
      );
    }

    if (!!data?.options?.length) {
      return <GotLoanOffer options={data.options} />;
    }
    return <EmptyLoans />;
  };

  if (isFetching) {
    return (
      <Skeleton
        width='100%'
        height='210px'
      />
    );
  }
  return (
    <TransitionAnimation>
      {data?.showLoanSection ? (
        <div className={styles.container}>
          <Typography className={styles.header}>Business funding</Typography>
          {renderContent()}
        </div>
      ) : (
        <></>
      )}
    </TransitionAnimation>
  );
};
