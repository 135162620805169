import { Box, Typography, Chip } from "@mui/material"
import { ExternalAccount } from "api/endpoints/financialAccounts";
import suitcaseIcon from 'assets/svg/suitcase-blue.svg';

import styles from './styles.module.scss';

interface AccountItemProps {
  account: ExternalAccount
}

const AccountItem = ({ account }: AccountItemProps ) => {
  return (
    <Box display={'flex'} gap={'12px'}>
        <img src={suitcaseIcon} />
        <Box>
          <Box display={'flex'} gap={'8px'} marginBottom={'4px'}>
          <Typography className={styles.cellAccountBoxName}>
            {account.name}
          </Typography>
          <Typography className={styles.cellAccountBoxLast4}>
            ({account.externalBankAccountDetails.last4})
          </Typography>
          </Box>
          <Chip label={account.bankName} />
        </Box>
      </Box>
  )
}

export default AccountItem;