import { useEffect } from 'react';
import { TextInput } from 'shared/ui/inputs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { Select } from 'shared/ui/inputs';
import NotificitionImg from 'assets/svg/notification-black.svg';
import { ManageAccess } from '../../../manageAccess';
import { useTranslationProvider } from 'providers/translation/translation.provider';
import styles from './style.module.scss';
import { FormikProps } from 'formik';
import { MenuItem, FormHelperText } from '@mui/material';
import { departmentList } from 'pages/settings/accountSettings/config/constants';
import { Typography } from 'shared/ui/typography';
import { Button } from 'shared/ui/buttons';
import { useDrawerStepperContext } from 'shared/ui/drawer-stepper';
import { SubUserFormValues } from 'pages/settings/accountSettings/types';
import { AccountItem } from '../../../accountItem';
import { useFinancialAccounts } from 'api/hooksApi/useFinancialAccounts';
import { Skeleton } from 'components/skeleton';
import { TransitionAnimation } from 'shared/ui/animations';
interface CreateSubUserFormProps {
  formik: FormikProps<SubUserFormValues>;
  onSubmitLoading: boolean;
}
export const CreateSubUserForm = (props: CreateSubUserFormProps) => {
  const { formik, onSubmitLoading } = props;
  const { unclosedFinancialAccounts, financialAccountsIsLoading } =
    useFinancialAccounts();
  const { t } = useTranslationProvider();
  const { goNext, lockForClose, unlockForClose } = useDrawerStepperContext();

  useEffect(() => {
    if (onSubmitLoading) {
      lockForClose();
    } else {
      unlockForClose();
    }
  }, [onSubmitLoading]);

  const renderSelectedPrimaryAcc = () => {
    const selectPrimary = unclosedFinancialAccounts.find(
      (acc) => acc.financialAccountId === formik.values.primaryAccounId
    );
    if (selectPrimary) {
      return <AccountItem item={selectPrimary} />;
    }
  };
  return financialAccountsIsLoading ? (
    <Skeleton
      width='100%'
      height='110px'
    />
  ) : (
    <TransitionAnimation>
      <form
        className={styles.form}
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.boxName}>
          <TextInput
            fullWidth
            label='First Name'
            {...formik.getFieldProps('firstName')}
            error={formik.touched.firstName && formik.errors.firstName}
          />
          <TextInput
            fullWidth
            label='Last Name'
            {...formik.getFieldProps('lastName')}
            error={formik.touched.lastName && formik.errors.lastName}
          />
        </div>
        <TextInput
          label='Email'
          {...formik.getFieldProps('email')}
          error={formik.touched.email && formik.errors.email}
        />
        <TextInput
          label='Phone number'
          name='phone'
          inputProps={{ maxLength: 10 }}
          value={formik.values.phone}
          onChange={(e) => {
            formik.setFieldValue('phone', e.target.value.replace(/\D/g, ''));
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && formik.errors.phone}
        />
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              fullWidth
              disableFuture
              label='Date of Birth'
              name='date'
              value={formik.values.date}
              onChange={(newDate) => {
                formik.setFieldValue('date', newDate);
              }}
              slotProps={{
                textField: {
                  error: formik.touched.date && !!formik.errors.date,
                },
              }}
            />
            {formik.touched.date && formik.errors.date && (
              <FormHelperText error>{formik.errors.date} </FormHelperText>
            )}
          </LocalizationProvider>
        </div>
        <Select
          label='Department'
          {...formik.getFieldProps('department')}
        >
          {departmentList.map((item) => (
            <MenuItem
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
        <ManageAccess formik={formik} />
        <div className={styles.primary}>
          <div className={styles.primaryHeader}>
            <div className={styles.primaryHeaderTitle}>
              <Typography>Primary card account:</Typography>
              <img src={NotificitionImg} />
            </div>
            <Button
              variant='text'
              onClick={() => goNext('1')}
            >
              {formik.values.primaryAccounId ? 'Edit' : '+ Add'}
            </Button>
          </div>
          {formik.values.primaryAccounId ? (
            renderSelectedPrimaryAcc()
          ) : (
            <Typography className={styles.primarySubTitle}>
              Please select financial account for authorized user&apos;s primary
              card. Primary card is an optional card that could have contact
              information of the authorized user for card verification purposes.
            </Typography>
          )}
        </div>
        <Button
          loading={onSubmitLoading}
          disabled={!formik.isValid || !formik.dirty}
          type='submit'
          className={styles.btn}
        >
          <div>
            {t('Settings.Add Authorized User', {
              defaultValue: 'Add Authorized User',
            })}
          </div>
        </Button>
      </form>
    </TransitionAnimation>
  );
};
